.header_navigation {
	float: left;
	transition: $transition1;
	.lang_switcher {
		display: none;
	}
	li {
		display: inline-block;
		@include rem('margin-right',44px);
		@include small-desktop {
			@include rem('margin-right',20px);
		}
		@include mid-desktop {
			@include rem('margin-right',25px);
		}
		&.current-menu-item {
			a {
				color: $themeColor1;
				border-color: $themeColor1;
			}
		}
		&:last-child {
			margin-right: 0;
		}
		a {
			font-weight: 400;
			font-weight: bold;
			color: $themeColor2;
			text-decoration: none;
			transition: $transition1;
			text-transform: uppercase;
			font-family: 'DIN Alternate';
			@include rem('font-size',14px);
			@include rem('padding-top',3px);
			@include rem('letter-spacing',1px);
			border-top: 1px solid transparent;
			@include mid-desktop {
				@include rem('font-size',13px);
			}
			@include small-desktop {
				@include rem('font-size',14px);
			}
			&:hover {
				color: $themeColor1;
				border-color: $themeColor1;
			}
		}
	}
}

.logo {
	float: left;
	@extend %centered;
	img {
		@include rem('height',90px);
		@include mid-desktop {
			@include rem('height',75px);
		}
	}
}

.lang_switcher {
	a {
		font-weight: 400;
		font-weight: bold;
		color: $themeColor2;
		text-decoration: none;
		transition: $transition1;
		text-transform: uppercase;
		font-family: 'DIN Alternate';
		@include rem('font-size',14px);
		@include rem('letter-spacing',1px);
		@include mid-desktop {
			@include rem('font-size',13px);
		}
		@include small-desktop {
			@include rem('font-size',14px);
		}
		&:hover {
			color: $themeColor1;
		}
	}
}

.hamburger {
    z-index: 5;
    width: 30px;
    float: right;
    height: auto;
    display: none;
    cursor: pointer;
    margin-top: 16px;
    position: relative;
    span {
        width: 100%;
        height: 4px;
        float: right;
        margin-bottom: 4px;
        border-radius: 4px;
        background: $themeColor1;
        transition: $transition1;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &.active {
        span {
            &:first-child {
                margin-top: 7px;
                margin-bottom: 2px;
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                margin-top: -14px;
                transform: rotate(-45deg);
            }
        }
    }
}