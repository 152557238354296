.custom-object-fit {
	background-size: cover;
	background-position: center center;
}
.thumb_holder {
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		-o-object-fit: cover;
		transition: $transition2;
	}
}
.centered_thumb {
	text-align: center;
	img {
		max-width: 100%;
		max-height: 100%;
	}
}
.anchor_icon {
	float: left;
	position: relative;
	border-radius: 100%;
	background: $themeColor1;
	transition: $transition1;
	@include rem('width',35px);
	@include rem('height',35px);
	img {
		@extend %centered;
	}
}
.view_more_button {
	float: left;
	width: 100%;
	position: relative;
	@include rem('min-height',40px);
	@include rem('padding-left',35px);
	&--second {
		.anchor_icon {
			background: $themeColor4;
		}
		span {
			color: $themeColor4;
		}
	}
	.anchor_icon {
		left: 0;
		top: 50%;
		position: absolute;
		transform: translateY(-50%);
	}
	span {
		float: left;
		transition: $transition1;
		@include rem('font-size',24px);
		@include rem('padding-top',7px);
		@include rem('padding-left',15px);
	}
}
.extended_container {
	width: 100%;
	margin: 0 auto;
	position: relative;
	max-width: 1760px;
	&--header {
		display: flex;
		align-items: center;
		@include rem('height',130px);
		justify-content: space-between;
		@include rem('padding',0 35px);
		@include tablet-big {
			padding: 0;
			max-width: 1080px;
		}
		@include big-desktop {
			padding: 0;
		}
	}
	&--highlighted {
		background: $themeColor2Light;
	}
	&--child_container {
		.extended_container__inner {
			float: left;
			width: 100%;
			height: 100%;
			background: $themeColor2Light;
		}
	}
}
.mid_container {
	width: 100%;
	margin: 0 auto;
	max-width: 1400px;
	position: relative;
	@include small-desktop {
		max-width: 970px;
	}
}
.first_section {
	float: left;
	width: 100%;
	height: 68.1vh;
	position: relative;
	&__content {
		width: 60%;
		z-index: 3;
		color: white;
		@extend %centered;
		text-align: center;
		line-height: 1.150em;
		@include rem('font-size',60px);
	}
}
.slideshow_main {
	float: left;
	width: 100%;
	&.no_slide {
		position: relative;
		&:before {
			top: 0;
			left: 0;
			z-index: 2;
			content: '';
			width: 100%;
			opacity: 0.4;
			height: 100%;
			background: black;
			position: absolute;
		}
	}
	&__item {
		float: left;
		width: 100%;
		height: 68.1vh;
	}
	&__thumb {
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		position: absolute;
		transition: $transition1;
	}
	&__text {
		width: 60%;
		z-index: 3;
		color: white;
		@extend %centered;
		text-align: center;
		line-height: 1.150em;
		@include rem('font-size',60px);
	}
	.owl-item {
		float: left;
		width: 100%;
		height: 68.1vh;
		position: relative;
		&:before {
			top: 0;
			left: 0;
			z-index: 2;
			content: '';
			width: 100%;
			opacity: 0.4;
			height: 100%;
			background: black;
			position: absolute;
		}
	}
}
.main_section {
	float: left;
	width: 100%;
	&--archive_container {
		float: left;
		width: 100%;
		@include rem('margin-top',85px);
	}
}
.information_boxes {
	z-index: 4;
	float: left;
	width: 100%;
	position: relative;
	@include rem('margin-top',-50px);
	@include rem('margin-bottom',150px);
	&__item {
		width: 50%;
		float: left;
		@include rem('padding',0 25px);
	}
	&__content {
		float: left;
		width: calc(100% - 35px);
		@include rem('padding-left',20px);
		h4 {
			line-height: 1.2em;
			color: $themeColor1;
			transition: $transition1;
			@include rem('font-size',28px);
			@include rem('margin-bottom',20px);
		}
		p {
			color: $themeColor3;
			line-height: 1.438em;
			transition: $transition1;
			@include rem('font-size',16px);
			font-family: 'DIN Alternate',sans-serif;
		}
	}
	&__container {
		@include desktop {
			display: flex;
		}
	}
	&__item_container {
		float: left;
		width: 100%;
		height: 100%;
		background: white;
		position: relative;
		transition: $transition1;
		box-shadow: 0px 30px 40px 0px rgba(219,219,219,1);
		-moz-box-shadow: 0px 30px 40px 0px rgba(219,219,219,1);
		-webkit-box-shadow: 0px 30px 40px 0px rgba(219,219,219,1);
		&:hover {
			transform: translateY(-10px);
			.anchor_icon {
				background: $themeColor4;
			}
			h4 {
				color: $themeColor4;
			}
			p {
				color: $themeColor4;
			}
		}
		a {
			float: left;
			width: 100%;
			height: 100%;
			@include rem('padding',50px);
			@include rem('padding-right',85px);
		}
	}
}
.row_articles {
	float: left;
	width: 100%;
	&__item {
		float: left;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		@include rem('margin-bottom',105px);
		@include rem('padding-bottom',110px);
		border-bottom: 1px solid $themeColor2;
		&:hover {
			.row_articles__title {
				color: $themeColor1;
			}
			p {
				color: $themeColor1;
			}
			.row_articles__thumb {
				img {
					transform: scale(1.05);
				}
			}
			.view_more_button {
				.anchor_icon {
					background: $themeColor1;
				}
				span {
					color: $themeColor1;
				}
			}
		}
		&--minified {
			@include rem('margin-bottom',70px);
			@include rem('padding-bottom',60px);
			&:last-child {
				margin-bottom: 0;
				border-bottom: none;
			}
			.row_articles__thumb {
				@include rem('height',245px);
			}
			.row_articles__content {
				&> a p {
					@include rem('font-size',16px);
				}
			}
		}
		&--no_thumb {
			display: block;
			.row_articles__title {
				width: 100%;
			}
			.row_articles__content {
				width: 100%;
				padding: 0 !important;
				@include rem('padding-bottom',60px,true);
			}
		}
		&--right_content {
			flex-direction: row-reverse;
			.row_articles__content {
				padding-right: 0;
				@include rem('padding-left',30px);
			}
		}
	}
	&__title {
		width: 100%;
		float: left;
		color: $themeColor4;
		line-height: 1.079em;
		transition: $transition1;
		@include rem('font-size',38px);
		@include rem('margin-bottom',27px);
	}
	&__content {
		float: left;
		width: 41.66667%;
		position: relative;
		@include rem('padding-right',30px);
		@include rem('padding-bottom',60px);
		h3 {
			@include rem('margin-bottom',45px);
		}
		p {
			float: left;
			width: 100%;
			color: $themeColor3;
			line-height: 1.611em;
			transition: $transition1;
			@include rem('font-size',18px);
			font-family: 'DIN Alternate',sans-serif;
		}
		.view_more_button {
			bottom: 0;
			position: absolute;
		}
	}
	&__thumb {
		float: left;
		width: 58.33333%;
		@include rem('height',400px);
	}
}
.column_articles {
	float: left;
	width: 100%;
	@include rem('margin-bottom',55px);
	&--sidebar {
		background: white;
		@include rem('padding',50px 40px);
		.column_articles {
			&__item {
				width: 100%;
				@include rem('margin-bottom',30px);
				&:last-child {
					margin-bottom: 0;
				}
			}
			&__thumb {
				width: 35%;
				height: auto;
			}
		}
		.member_info {
			width: 65%;
			&__name {
				@include rem('font-size',22px);
			}
		}
	}
	&__main_title {
		float: left;
		width: 100%;
		color: $themeColor4;
		@include rem('font-size',38px);
		@include rem('margin-bottom',50px);
	}
	&__item {
		float: left;
		width: 33.333%;
		@include rem('padding',0 25px);
		@include rem('margin-bottom',25px);
	}
	&__item_container {
		float: left;
		width: 100%;
		&:hover {
			.member_info {
				&__name {
					color: $themeColor1;
				}
				&__role {
					color: $themeColor1;
				}
				&__list {
					li {
						a {
							color: $themeColor1;
						}
					}
				}
			}
		}
	}
	&__thumb {
		width: 40%;
		float: left;
		text-align: center;
		@include rem('max-height',250px);
		img {
			width: auto;
			max-height: 100%;
		}
	}
}
.member_info {
	width: 60%;
	float: left;
	@include rem('padding-left',20px);
	&__name {
		float: left;
		width: 100%;
		color: $themeColor4;
		transition: $transition1;
		@include rem('font-size',28px);
		@include rem('margin-bottom',9px);
	}
	&__role {
		float: left;
		width: 100%;
		color: $themeColor4;
		transition: $transition1;
		@include rem('font-size',18px);
	}
	&__list {
		float: left;
		@include rem('margin-top',32px);
		li {
			float: left;
			width: 100%;
			position: relative;
			@include rem('padding-top',10px);
			@include rem('margin-bottom',5px);
			&:first-child {
				padding-top: 0;
			}
			&.telephone {
				&:before {
					top: 0;
					left: 0;
					content: '';
					float: left;
					position: absolute;
					@include rem('width',22px);
					@include rem('height',22px);
					background: url('../images/telephone.svg') no-repeat center;
					background-size: contain;
				}
			}
			&.mail {
				&:before {
					left: 0;
					content: '';
					float: left;
					position: absolute;
					@include rem('top',10px);
					@include rem('width',22px);
					@include rem('height',18px);
					background: url('../images/email.svg') no-repeat center;
					background-size: contain;
				}
			}
			&.facebook {
				&:before {
					content: '';
					float: left;
					position: absolute;
					@include rem('top',8px);
					@include rem('left',4px);
					@include rem('width',13px);
					@include rem('height',23px);
					background: url('../images/facebook.svg') no-repeat center;
					background-size: contain;
				}
			}
			a {
				position: relative;
				color: $themeColor3;
				text-decoration: none;
				transition: $transition1;
				@include rem('font-size',18px);
				@include rem('padding-left',35px);
				font-family: 'DIN Alternate',sans-serif;
			}
		}
	}
}
.page_title {
	float: left;
	width: 100%;
	color: $themeColor4;
	@include rem('font-size',38px);
	@include rem('margin-bottom',40px);
}
.page_desc {
	width: 70%;
	float: left;
	line-height: 1.35em;
	color: $themeColor3;
	@include rem('font-size',18px);
	@include rem('margin-bottom',20px);
	font-family: 'DIN Alternate',sans-serif;
}

body {
	&.activePopUp {
		.property_popup {
			opacity: 1;
			visibility: visible;
			z-index: 5;
		}
	}
}

// Contact Page
.left_side_section {
	float: left;
	width: 66.66667%;
	@include rem('padding',0 25px);
	.row_articles {
		@include rem('margin-top',75px);
	}
}
.right_side_section {
	float: left;
	width: 33.33333%;
	@include rem('padding',0 25px);
	&__form {
		float: left;
		width: 100%;
		background: white;
		@include rem('padding',40px);
		@include rem('margin-bottom',50px);
		&.popup_form {
			float: left;
			width: 33.333%;
			margin-bottom: 0;
			@include rem('max-width',500px);
			@include rem('padding-bottom',40px);
		}
		&__title {
			color: $themeColor4;
			@include rem('font-size',28px);
			@include rem('margin-bottom',20px);
		}
		.gfield_label {
			display: none;
		}
		.gfield {
			@include rem('margin-bottom',20px);
			input {
				width: 100%;
				@include rem('height',40px);
				@include rem('padding',10px);
				@include rem('font-size',16px);
				border: 1px solid $themeColor2;
				@include rem('padding-top',14px);
				font-family: 'DIN Alternate',sans-serif;
				@include placeholder {
					color: $themeColor4;
					@include rem('padding-top',2px);
					font-family: 'DIN Alternate',sans-serif;
				}
			}
			textarea {
				width: 100%;
				resize: none;
				@include rem('height',170px);
				@include rem('font-size',16px);
				border: 1px solid $themeColor2;
				@include rem('padding-top',10px);
				@include rem('padding-left',10px);
				font-family: 'DIN Alternate',sans-serif;
				@include placeholder {
					color: $themeColor4;
					font-family: 'DIN Alternate',sans-serif;
				}
			}
		}
		.gform_footer {
			float: left;
			width: 100%;
		}
		.gform_button {
			width: 100%;
			float: left;
			color: white;
			border: none;
			cursor: pointer;
			font-weight: 400;
			background: $themeColor1;
			transition: $transition1;
			@include rem('height',40px);
			@include rem('font-size',16px);
			border: 1px solid $themeColor1;
			font-family: 'DIN Alternate',sans-serif;
			&:hover {
				color: $themeColor1;
				background: transparent;
			}
		}
	}
}
.contact_description {
	float: left;
	width: 100%;
	background: white;
	@include rem('padding',50px);
	p {
		color: $themeColor3;
		line-height: 1.556em;
		@include rem('font-size',18px);
	}
}
.contact_list {
	width: 100%;
	float: left;
	@include rem('padding',0 25px);
	ul {
		float: left;
		width: 100%;
		@include rem('margin-top',37px);
		li {
			float: left;
			width: 100%;
			@include rem('margin-bottom',24px);
			&:last-child {
				margin-bottom: 0;
			}
		}
		a {
			color: $themeColor4;
			text-decoration: none;
		}
		span {
			color: $themeColor4;
			@include rem('font-size',28px);
		}
	}
}
// End of Contact Page

// Search Box
.search_box {
	float: left;
	width: 100%;
	@include rem('margin-top',63px);
	@include rem('margin-bottom',115px);
	@include rem('padding-bottom',115px);
	border-bottom: 1px solid $themeColor2;
	&__container {
		width: 100%;
		float: left;
		background: white;
	}
	&__top_header {
		float: left;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include rem('margin-bottom',40px);
	}
	&__header {
		float: left;
		width: 100%;
		@include rem('padding',25px 30px 0);
	}
	&__result_number {
		float: left;
		color: $themeColor5;
		@include rem('font-size',20px);
		font-family: 'DIN Alternate',sans-serif;
	}
	&__sorting {
		width: auto;
		float: right;
		h4 {
			color: $themeColor5;
			display: inline-block;
			@include rem('font-size',20px);
			@include rem('padding-right',5px);
			font-family: 'DIN Alternate',sans-serif;
		}
		select {
			outline: none;
			border-radius: 6px;
			color: $themeColor5;
			display: inline-block;
			@include rem('padding',10px);
			@include rem('font-size',18px);
			border: 1px solid $themeColor5;
			@include rem('padding-left',5px);
			@include rem('padding-right',20px);
			font-family: 'DIN Alternate',sans-serif;
		}
	}
	&__input {
		float: left;
		width: 100%;
		position: relative;
		@include rem('height',60px);
		@include rem('padding',10px);
		border: 1px solid $themeColor5;
		@include rem('border-radius',4px);
		@include rem('padding-left',45px);
		input {
			float: left;
			width: 100%;
			border: none;
			background: transparent;
			&[type="submit"] {
				top: 50%;
				opacity: 0;
				float: left;
				position: absolute;
				@include rem('left',11px);
				@include rem('width',25px);
				@include rem('height',25px);
				transform: translateY(-50%);
			}
			&[type="text"] {
				float: left;
				height: 100%;
				width: calc(100% - 25px);
				@include placeholder {
					@include rem('font-size',22px);
					font-family: 'DIN Alternate',sans-serif;
				}
			}
		}
		.submit_button {
			top: 50%;
			position: absolute;
			@include rem('left',11px);
			@include rem('width',25px);
			@include rem('height',25px);
			transform: translateY(-50%);
			background: url('../images/search.svg') no-repeat center;
			@include rem('background-size',20px);
		}
	}
	&__results {
		float: left;
		width: 100%;
		background: white;
		@include rem('padding-top',15px);
	}
	&__item {
		float: left;
		width: 100%;
		@include rem('padding',30px);
		border-bottom: 1px solid $themeColor2;
	}
	&__thumb {
		float: left;
		width: 33.33333%;
		@include rem('height',245px);
	}
	&__content {
		float: left;
		color: black;
		width: 66.66667%;
		@include rem('margin-top',10px);
		@include rem('padding-left',30px);
		font-family: 'DIN Alternate',sans-serif;
	}
	&__location {
		float: left;
		width: 100%;
		line-height: 1.2em;
		@include rem('font-size',20px);
		@include rem('margin-bottom',18px);
	}
	&__location_name {
		float: left;
		width: 100%;
		line-height: 1.25em;
		@include rem('font-size',24px);
		@include rem('margin-bottom',19px);
	}
	&__specs {
		float: left;
		width: 100%;
		@include rem('margin-bottom',19px);
		li {
			float: left;
			display: inline-block;
			&:last-child {
				span {
					margin-right: 0;
				}
			}
		}
		span {
			float: left;
			font-weight: bold;
			@include rem('font-size',22px);
			@include rem('margin-right',30px);
		}
	}
	&__other {
		float: left;
		width: 100%;
		@include rem('font-size',18px);
		@include rem('margin-bottom',10px);
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__footer {
		float: left;
		width: 100%;
		text-align: center;
		@include rem('padding',30px 0);
	}
}
// End of Search Box

// Map Page
.map_section {
	float: left;
	width: 100%;
	@include rem('margin-top',62px);
	@include rem('margin-bottom',80px);
	&__map_locations {
		float: left;
		width: 100%;
		background: $themeColor4;
		@include rem('height',710px);
		@include rem('margin-bottom',60px);
	}
	&__legend {
		float: left;
		width: 100%;
	}
	&__legend_button {
		float: left;
		color: white;
		max-width: 30%;
		text-align: center;
		text-decoration: none;
		background: $themeColor1;
		transition: $transition1;
		@include rem('min-width',350px);
		@include rem('font-size',24px);
		border: 1px solid $themeColor1;
		@include rem('padding',10px 30px);
		&:hover {
			color: $themeColor1;
			background: transparent;
		}
	}
	&__legend_list {
		width: auto;
		float: right;
		li {
			float: left;
			width: auto;
			display: flex;
			align-items: center;
			@include rem('margin-right',30px);
			&:last-child {
				margin-right: 0;
			}
			p {
				float: left;
				color: $themeColor3;
				@include rem('font-size',18px);
				@include rem('margin-left',10px);
				font-family: 'DIN Alternate',sans-serif;
			}
		}
	}
	&__icon {
		float: left;
		@include rem('width',33px);
		@include rem('height',43px);
		&.car {
			background: url('../images/map-car-active.svg') no-repeat center;
			background-size: contain;
			&--not_active {
				background: url('../images/map-car-not-active.svg') no-repeat center;
				background-size: contain;
			}
		}
		&.appartment {
			background: url('../images/map-appartment-active.svg') no-repeat center;
			background-size: contain;
			&--not_active {
				background: url('../images/map-appartment-not-active.svg') no-repeat center;
				background-size: contain;
			}
		}
	}
	.gmnoprint {
		&:not(.gm-bundled-control) {
			display: none;
		}
		.gmnoprint {
			display: block;
		}
	}
}
.locations_section {
	float: left;
	width: 100%;
	position: relative;
	@include rem('margin-bottom',80px);
	&:last-child {
		@include rem('margin-bottom',30px);
		@include rem('padding-bottom',80px);
		&:after {
			left: 50%;
			bottom: 0;
			content: '';
			width: 85.4%;
			position: absolute;
			background: $themeColor2;
			@include rem('height',1px);
			transform: translateX(-50%);
		}
	}
	&__header {
		float: left;
		width: 100%;
		display: flex;
		padding: 0 7.3%;
		position: relative;
		flex-direction: column;
		justify-content: flex-end;
		@include rem('min-height',280px);
		@include rem('padding-top',40px);
		@include rem('margin-bottom',5px);
		@include rem('padding-bottom',40px);
		background: url('../images/picture-1.jpg') no-repeat center;
		background-size: cover;
		&:before {
			top: 0;
			left: 0;
			z-index: 2;
			content: '';
			width: 100%;
			opacity: 0.4;
			height: 100%;
			background: black;
			position: absolute;
		}
		h4 {
			z-index: 3;
			color: white;
			line-height: 1.2em;
			position: relative;
			@include rem('font-size',38px);
			@include rem('margin-bottom',20px);
		}
		p {
			z-index: 3;
			width: 60%;
			color: white;
			position: relative;
			line-height: 1.3em;
			@include rem('font-size',18px);
			font-family: 'DIN Alternate',sans-serif;
		}
	}
	&__popup {
		float: left;
		width: 100%;
		&.onlyChild {
			@include rem('min-width',250px);
			.locations_section__item {
				width: 100% !important;
			}
		}
		&.twoChildren {
			.locations_section__item {
				width: 50% !important;
			}
		}
		.locations_section__item {
			float: left;
			width: 33.333% !important;
			@include rem('padding',0 10px);
			@include rem('margin-top',20px);
			@include rem('min-height',180px);
			&--disabled {
				.locations_section__item_header {
					color: $themeColor4;
				}
			}
		}
		.locations_section__item_header {
			color: $themeColor1;
			@include rem('font-size',20px);
		}
		.locations_section__button {
			@include rem('padding',0 10px);
		}
		.locations_section__inner_list {
			@include rem('padding-bottom',65px);
			li {
				span {
					@include rem('font-size',15px);
				}
			}
		}
	}
	&__list {
		float: left;
		width: 100%;
		padding: 0 7.3%;
	}
	&__list_container {
		float: left;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		float: left;
		width: 16.66667%;
		position: relative;
		@include rem('padding',0 25px);
		@include rem('margin-top',50px);
		@include rem('min-height',250px);
		@include rem('padding-bottom',70px);
	}
	&__item_header {
		line-height: 1.2em;
		color: $themeColor4;
		border-bottom: 1px solid;
		@include rem('font-size',20px);
		@include rem('padding-bottom',2px);
	}
	&__inner_list {
		float: left;
		width: 100%;
		li {
			float: left;
			width: 100%;
			@include rem('margin-top',12px);
			span {
				@include rem('font-size',18px);
				font-family: 'DIN Alternate',sans-serif;
			}
		}
	}
	&__button {
		left: 0;
		bottom: 0;
		float: left;
		width: 100%;
		position: absolute;
		@include rem('padding',0 25px);
		a {
			float: left;
			width: 100%;
			border: 1px solid;
			position: relative;
			text-align: center;
			color: $themeColor1;
			text-decoration: none;
			transition: $transition1;
			@include rem('height',30px);
			@include rem('padding',5px);
			@include rem('font-size',16px);
			font-family: 'DIN Alternate',sans-serif;
			&:after {
				top: 0;
				left: 0;
				opacity: 0;
				width: 100%;
				color: white;
				height: 100%;
				position: absolute;
				visibility: hidden;
				background: $themeColor1;
				transition: $transition1;
				@include rem('padding',5px);
				content: attr(data-hover-text);
			}
			&:hover {
				&:after {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		&--disabled {
			a {
				width: auto;
				border: none;
				height: auto;
				padding-top: 0;
				padding-left: 0;
				text-align: left;
				color: $themeColor2;
				cursor: context-menu;
				&:after {
					display: none;
				}
			}
		}
	}
}
#map {
	float: left;
	width: 100%;
	height: 100%;
}
.ie {
	#map {
		display: table-cell;
	}
}
// End of Map Page

// Form Changes
.gform_confirmation_message {
	line-height: 1.2em;
	text-align: center;
	color: $themeColor3;
	@include rem('font-size',20px);
}
.validation_error {
	text-align: center;
	line-height: 1.2em;
	@include rem('font-size',16px);
	@include rem('margin-bottom',10px);
}
.validation_message {
	color: $errorColor;
	line-height: 1.2em;
	@include rem('font-size',16px);
	@include rem('margin-top',5px);
}
// End of Form Changes


// Property PopUp
.property_popup {
	top: 0;
	left: 0;
	opacity: 0;
	z-index: -1;
	float: left;
	width: 100%;
	height: 100%;
	position: fixed;
	visibility: hidden;
	background: rgba(0,0,0,0.7);
	@include rem('padding',80px 70px);
	&__container {
		float: left;
		width: 100%;
		height: 100%;
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
	}
	&__close_button {
		top: 10%;
		z-index: 5;
		cursor: pointer;
		position: absolute;
		@include rem('right',60px);
		@include rem('width',20px);
		@include rem('height',20px);
		background: url('../images/close.svg') no-repeat center;
		background-size: cover;
	}
}
// End of Property PopUp

// Error Page
.error404 {
	.main_content {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}
}
.error_page {
	float: left;
	width: 100%;
	&__title {
		text-align: center;
		color: $themeColor1;
		@include rem('font-size',110px);
	}
	&__desc {
		text-align: center;
		color: $themeColor4;
		line-height: 1.3em;
		@include rem('font-size',30px);
	}
}
// End of Error Page

// Slideshow Changes
.slideshow_section {
	top: 0;
	left: 0;
	z-index: 1;
	float: left;
	width: 100%;
	height: 100%;
	position: absolute;
	&__thumb {
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		visibility: hidden;
		position: absolute;
		opacity: 0 !important;
		transition: $transition1;
		&.active {
			visibility: visible;
			opacity: 1 !important;
		}
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
// End of Slideshow Changes

.cookie_popup {
  left: 0;
  bottom: 0;
  opacity: 0;
  z-index: 15;
  width: 100%;
  position: fixed;
  background: white;
  transition: $transition1;
  transform: translateY(0);
  transform: translateY(100%);
  @include rem('height', 60px);
  @include rem('padding-top', 11px);
  &.active {
    opacity: 1;
    transform: translateY(0);
  }
  p {
    float: left;
    @include rem('font-size', 18px);
    @include rem('margin-top', 11px);
    @include rem('margin-right',15px);
  }
}

.cookie_buttons {
  float: right;
  a {
    float: left;
    color: $themeColor3;
    text-decoration: none;
    @include rem('padding',10px);
    @include rem('font-size', 15px);
    @include rem('margin-top', 3px);
    @include rem('margin-right',15px);
    @include rem('border-radius',20px); 
	font-family: 'DIN Alternate',sans-serif;
  	&.accept_cookie {
		color: white;
  		margin-right: 0;
	    background: $themeColor3;
    	@include rem('padding',10px 20px);
  	}
  }
}

.article_content {
	float: left;
	width: 100%;
	line-height: 1.7em;
	@include rem('padding',40px 0);
	@include rem('font-size',16px);
	h1,
	h2 {
	  float: left;
	  width: 100%;
	  font-weight: 500;
	  line-height: 1.2em;
	  color: $themeColor3;
	  @include rem('font-size',32px);
	  @include rem('margin-bottom',20px);
	}
	h3,
	h4 {
	  float: left;
	  width: 100%;
	  font-weight: 500;
	  line-height: 1.2em;
	  color: $themeColor3;
	  @include rem('font-size',26px);
	  @include rem('margin-bottom',20px);
	}
	h5,
	h6 {
	  float: left;
	  width: 100%;
	  font-weight: 500;
	  line-height: 1.2em;
	  color: $themeColor3;
	  @include rem('font-size',22px);
	  @include rem('margin-bottom',20px);
	}
	p {
		line-height: 1.7em;
		@include rem('font-size',16px);
		@include rem('margin-bottom',20px);
	}
	strong {
		line-height: 1.7em;
		@include rem('margin',0 5px);
		@include rem('font-size',16px);
	}
	em {
		line-height: 1.7em;
		@include rem('margin',0 5px);
		@include rem('font-size',16px);
	}
	ul {
		@include rem('margin',10px 0);
		@include rem('padding-left',15px);
		li {
		  list-style-type: disc;
		  ul {
		    @include rem('margin',3px 0);
		    li {
		      list-style-type: circle;
		    }
		  }
		}
	}
	ol {
		@include rem('margin',10px 0);
		@include rem('padding-left',15px);
		li {
		  list-style-type: decimal;
		  ul {
		    @include rem('margin',3px 0);
		    li {
		      list-style-type: circle;
		    }
		  }
		}
	}
	a {
		outline: 0;
		color: #0089e5;
		line-height: 1.7em;
		text-decoration: none;
		@include rem('font-size',16px);
	}
	blockquote {
		float: left;
		width: 100%;
		color: black;
		text-align: center;
		font-style: italic;
		line-height: 1.7em;
		background: $themeColor4;
		@include rem('padding',20px);
		@include rem('margin',10px 0);
		@include rem('font-size',18px);
	}
	img {
		@include rem('margin-top',10px);
		@include rem('margin-bottom',10px);
		&.alignnone {
		  width: 100%;
		}
	}
}