@font-face {
    font-family: 'Unna';
    src: url('../../fonts/Unna-Regular.woff2') format('woff2'),
        url('../../fonts/Unna-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Alternate';
    src: url('../../fonts/DINAlternate-Bold.woff2') format('woff2'),
        url('../../fonts/DINAlternate-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Alternate';
    src: url('../../fonts/DINAlternate-Regular.woff2') format('woff2'),
        url('../../fonts/DINAlternate-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Unna';
    src: url('../../fonts/Unna-Italic.woff2') format('woff2'),
        url('../../fonts/Unna-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Unna';
    src: url('../../fonts/Unna-BoldItalic.woff2') format('woff2'),
        url('../../fonts/Unna-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Unna';
    src: url('../../fonts/Unna-Bold.woff2') format('woff2'),
        url('../../fonts/Unna-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}