.footer_container {
	float: left;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	@include rem('margin-top',80px);
	@include rem('margin-bottom',73px);
}
.footer_logo {
	@include rem('margin-bottom',28px);
	img {
		@include rem('height',75px);
	}
}
.footer_text {
	float: left;
	color: $themeColor2;
	@include rem('font-size',18px);
	@include rem('margin-bottom',13px);
	a {
		color: $themeColor2;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}