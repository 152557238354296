/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease;
}

.owl-carousel .owl-item .owl-lazy:not([src]), .owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots, .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav {
  margin-top: 10px;
}

.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: .5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

/*====================================
=            GRID GLOBALS            =
====================================*/
/*=====  End of GRID GLOBALS  ======*/
/*===================================
=            TRANSITIONS            =
===================================*/
/*=====  End of TRANSITIONS  ======*/
/*=================================
=            STRUCTURE            =
=================================*/
/*=====  End of STRUCTURE  ======*/
/*==============================
=            COLORS            =
==============================*/
/*=====  End of COLORS  ======*/
@font-face {
  font-family: 'Unna';
  src: url("../../fonts/Unna-Regular.woff2") format("woff2"), url("../../fonts/Unna-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Alternate';
  src: url("../../fonts/DINAlternate-Bold.woff2") format("woff2"), url("../../fonts/DINAlternate-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Alternate';
  src: url("../../fonts/DINAlternate-Regular.woff2") format("woff2"), url("../../fonts/DINAlternate-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Unna';
  src: url("../../fonts/Unna-Italic.woff2") format("woff2"), url("../../fonts/Unna-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Unna';
  src: url("../../fonts/Unna-BoldItalic.woff2") format("woff2"), url("../../fonts/Unna-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Unna';
  src: url("../../fonts/Unna-Bold.woff2") format("woff2"), url("../../fonts/Unna-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

header,
main,
footer {
  float: left;
  width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

*:after, *:before {
  box-sizing: border-box;
}

.headerWrap,
.mainWrap,
.footerWrap {
  position: relative;
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
div,
ul,
input,
blockquote,
span,
button,
i,
b,
em,
strong {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

i,
em {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

body {
  overflow-x: hidden;
  position: relative;
}

/*=====================================
=            MEDIA QUERIES            =
=====================================*/
/*=====  End of MEDIA QUERIES  ======*/
/*==============================
=            MIXINS            =
==============================*/
/*=====  End of MIXINS  ======*/
/*========================================
=            EXTEND SELECTORS            =
========================================*/
.container,
.row {
  *zoom: 1;
}

.container:before,
.row:before, .container:after,
.row:after {
  content: ' ';
  display: table;
}

.container:after,
.row:after {
  clear: both;
}

.logo, .anchor_icon img, .first_section__content, .slideshow_main__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.headerWrap,
.mainWrap,
.footerWrap {
  float: left;
  width: 100%;
  clear: both;
}

/*=====  End of EXTEND SELECTORS  ======*/
.column-1 {
  width: 8.33333%;
}

.column-2 {
  width: 16.66667%;
}

.column-3 {
  width: 25%;
}

.column-4 {
  width: 33.33333%;
}

.column-5 {
  width: 41.66667%;
}

.column-6 {
  width: 50%;
}

.column-7 {
  width: 58.33333%;
}

.column-8 {
  width: 66.66667%;
}

.column-9 {
  width: 75%;
}

.column-10 {
  width: 83.33333%;
}

.column-11 {
  width: 91.66667%;
}

.column-12 {
  width: 100%;
}

.wow {
  visibility: hidden;
}

[class*='column-'] {
  float: left;
  min-height: 1px;
}

.has_gutter [class*='column-'] {
  padding: 0 25px;
}

.row {
  margin-left: -25px;
  margin-left: -1.5625rem;
  margin-right: -25px;
  margin-right: -1.5625rem;
}

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  max-width: 1500px;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .container {
    max-width: 732px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .container {
    max-width: 970px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .container {
    max-width: 1060px;
  }
  .container--mid {
    padding: 0 70px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .container {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .show-mob {
    display: none !important;
  }
  .show-tab {
    display: none !important;
  }
  .show-tab-big {
    display: none !important;
  }
}

.hidden-mob {
  display: block;
}

.hidden {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .column-mob-1 {
    width: 8.33333%;
  }
  .column-mob-2 {
    width: 16.66667%;
  }
  .column-mob-3 {
    width: 25%;
  }
  .column-mob-4 {
    width: 33.33333%;
  }
  .column-mob-5 {
    width: 41.66667%;
  }
  .column-mob-6 {
    width: 50%;
  }
  .column-mob-7 {
    width: 58.33333%;
  }
  .column-mob-8 {
    width: 66.66667%;
  }
  .column-mob-9 {
    width: 75%;
  }
  .column-mob-10 {
    width: 83.33333%;
  }
  .column-mob-11 {
    width: 91.66667%;
  }
  .column-mob-12 {
    width: 100%;
  }
  .show-mob {
    display: block !important;
  }
  .hidden-mob {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .column-tab-1 {
    width: 8.33333%;
  }
  .column-tab-2 {
    width: 16.66667%;
  }
  .column-tab-3 {
    width: 25%;
  }
  .column-tab-4 {
    width: 33.33333%;
  }
  .column-tab-5 {
    width: 41.66667%;
  }
  .column-tab-6 {
    width: 50%;
  }
  .column-tab-7 {
    width: 58.33333%;
  }
  .column-tab-8 {
    width: 66.66667%;
  }
  .column-tab-9 {
    width: 75%;
  }
  .column-tab-10 {
    width: 83.33333%;
  }
  .column-tab-11 {
    width: 91.66667%;
  }
  .column-tab-12 {
    width: 100%;
  }
  .show-tab {
    display: block !important;
  }
  .hidden-tab {
    display: none !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .column-tab-big-1 {
    width: 8.33333%;
  }
  .column-tab-big-2 {
    width: 16.66667%;
  }
  .column-tab-big-3 {
    width: 25%;
  }
  .column-tab-big-4 {
    width: 33.33333%;
  }
  .column-tab-big-5 {
    width: 41.66667%;
  }
  .column-tab-big-6 {
    width: 50%;
  }
  .column-tab-big-7 {
    width: 58.33333%;
  }
  .column-tab-big-8 {
    width: 66.66667%;
  }
  .column-tab-big-9 {
    width: 75%;
  }
  .column-tab-big-10 {
    width: 83.33333%;
  }
  .column-tab-big-11 {
    width: 91.66667%;
  }
  .column-tab-big-12 {
    width: 100%;
  }
  .show-tablet-big {
    display: block !important;
  }
  .hidden-tablet-big {
    display: none !important;
  }
}

.is-full-width {
  width: 100vw !important;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  max-width: none;
}

.no_padding {
  padding: 0 !important;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
}

.no-flexboxlegacy body {
  display: block !important;
}

body {
  font-family: 'Unna', sans-serif;
  font-size: 14px;
  color: black;
  position: relative;
  overflow-x: hidden;
}

input,
textarea,
select,
option {
  opacity: 1;
  border-radius: 0;
  appearance: none;
  -webkit-appearance: none;
  font-family: 'Unna', sans-serif;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder,
option::-webkit-input-placeholder {
  font-family: 'Unna', sans-serif;
  color: black;
  opacity: 1;
  font-size: 14px;
}

input:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder,
option:-moz-placeholder {
  font-family: 'Unna', sans-serif;
  color: black;
  opacity: 1;
  font-size: 14px;
}

input::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder,
option::-moz-placeholder {
  font-family: 'Unna', sans-serif;
  color: black;
  opacity: 1;
  font-size: 14px;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder,
option:-ms-input-placeholder {
  font-family: 'Unna', sans-serif;
  color: black;
  opacity: 1;
  font-size: 14px;
}

select::-ms-expand {
  display: none;
}

/*=================================
=            Normalize            =
=================================*/
mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  background: #eee;
  font-family: 'Courier 10 Pitch', Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace;
  font-size: 15px;
  font-size: 0.9375rem;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

/* Do not show the outline on the skip link target. */
#main[tabindex='-1']:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*=====  End of Normalize  ======*/
.header_navigation {
  float: left;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.header_navigation .lang_switcher {
  display: none;
}

.header_navigation li {
  display: inline-block;
  margin-right: 44px;
  margin-right: 2.75rem;
}

@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .header_navigation li {
    margin-right: 20px;
    margin-right: 1.25rem;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .header_navigation li {
    margin-right: 25px;
    margin-right: 1.5625rem;
  }
}

.header_navigation li.current-menu-item a {
  color: #461540;
  border-color: #461540;
}

.header_navigation li:last-child {
  margin-right: 0;
}

.header_navigation li a {
  font-weight: 400;
  font-weight: bold;
  color: #5c584d;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  text-transform: uppercase;
  font-family: 'DIN Alternate';
  font-size: 14px;
  font-size: 0.875rem;
  padding-top: 3px;
  padding-top: 0.1875rem;
  letter-spacing: 1px;
  letter-spacing: 0.0625rem;
  border-top: 1px solid transparent;
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .header_navigation li a {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .header_navigation li a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.header_navigation li a:hover {
  color: #461540;
  border-color: #461540;
}

.logo {
  float: left;
}

.logo img {
  height: 90px;
  height: 5.625rem;
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .logo img {
    height: 75px;
    height: 4.6875rem;
  }
}

.lang_switcher a {
  font-weight: 400;
  font-weight: bold;
  color: #5c584d;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  text-transform: uppercase;
  font-family: 'DIN Alternate';
  font-size: 14px;
  font-size: 0.875rem;
  letter-spacing: 1px;
  letter-spacing: 0.0625rem;
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .lang_switcher a {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .lang_switcher a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.lang_switcher a:hover {
  color: #461540;
}

.hamburger {
  z-index: 5;
  width: 30px;
  float: right;
  height: auto;
  display: none;
  cursor: pointer;
  margin-top: 16px;
  position: relative;
}

.hamburger span {
  width: 100%;
  height: 4px;
  float: right;
  margin-bottom: 4px;
  border-radius: 4px;
  background: #461540;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.hamburger span:last-child {
  margin-bottom: 0;
}

.hamburger.active span:first-child {
  margin-top: 7px;
  margin-bottom: 2px;
  transform: rotate(45deg);
}

.hamburger.active span:nth-child(2) {
  opacity: 0;
}

.hamburger.active span:nth-child(3) {
  margin-top: -14px;
  transform: rotate(-45deg);
}

.custom-object-fit {
  background-size: cover;
  background-position: center center;
}

.thumb_holder {
  overflow: hidden;
}

.thumb_holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
}

.centered_thumb {
  text-align: center;
}

.centered_thumb img {
  max-width: 100%;
  max-height: 100%;
}

.anchor_icon {
  float: left;
  position: relative;
  border-radius: 100%;
  background: #461540;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  width: 35px;
  width: 2.1875rem;
  height: 35px;
  height: 2.1875rem;
}

.view_more_button {
  float: left;
  width: 100%;
  position: relative;
  min-height: 40px;
  min-height: 2.5rem;
  padding-left: 35px;
  padding-left: 2.1875rem;
}

.view_more_button--second .anchor_icon {
  background: #86763f;
}

.view_more_button--second span {
  color: #86763f;
}

.view_more_button .anchor_icon {
  left: 0;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}

.view_more_button span {
  float: left;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  font-size: 24px;
  font-size: 1.5rem;
  padding-top: 7px;
  padding-top: 0.4375rem;
  padding-left: 15px;
  padding-left: 0.9375rem;
}

.extended_container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  max-width: 1760px;
}

.extended_container--header {
  display: flex;
  align-items: center;
  height: 130px;
  height: 8.125rem;
  justify-content: space-between;
  padding: 0 35px;
  padding: 0 2.1875rem;
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .extended_container--header {
    padding: 0;
    max-width: 1080px;
  }
}

@media only screen and (min-width: 1780px) {
  .extended_container--header {
    padding: 0;
  }
}

.extended_container--highlighted {
  background: rgba(178, 173, 162, 0.1);
}

.extended_container--child_container .extended_container__inner {
  float: left;
  width: 100%;
  height: 100%;
  background: rgba(178, 173, 162, 0.1);
}

.mid_container {
  width: 100%;
  margin: 0 auto;
  max-width: 1400px;
  position: relative;
}

@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .mid_container {
    max-width: 970px;
  }
}

.first_section {
  float: left;
  width: 100%;
  height: 68.1vh;
  position: relative;
}

.first_section__content {
  width: 60%;
  z-index: 3;
  color: white;
  text-align: center;
  line-height: 1.150em;
  font-size: 60px;
  font-size: 3.75rem;
}

.slideshow_main {
  float: left;
  width: 100%;
}

.slideshow_main.no_slide {
  position: relative;
}

.slideshow_main.no_slide:before {
  top: 0;
  left: 0;
  z-index: 2;
  content: '';
  width: 100%;
  opacity: 0.4;
  height: 100%;
  background: black;
  position: absolute;
}

.slideshow_main__item {
  float: left;
  width: 100%;
  height: 68.1vh;
}

.slideshow_main__thumb {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.slideshow_main__text {
  width: 60%;
  z-index: 3;
  color: white;
  text-align: center;
  line-height: 1.150em;
  font-size: 60px;
  font-size: 3.75rem;
}

.slideshow_main .owl-item {
  float: left;
  width: 100%;
  height: 68.1vh;
  position: relative;
}

.slideshow_main .owl-item:before {
  top: 0;
  left: 0;
  z-index: 2;
  content: '';
  width: 100%;
  opacity: 0.4;
  height: 100%;
  background: black;
  position: absolute;
}

.main_section {
  float: left;
  width: 100%;
}

.main_section--archive_container {
  float: left;
  width: 100%;
  margin-top: 85px;
  margin-top: 5.3125rem;
}

.information_boxes {
  z-index: 4;
  float: left;
  width: 100%;
  position: relative;
  margin-top: -50px;
  margin-top: -3.125rem;
  margin-bottom: 150px;
  margin-bottom: 9.375rem;
}

.information_boxes__item {
  width: 50%;
  float: left;
  padding: 0 25px;
  padding: 0 1.5625rem;
}

.information_boxes__content {
  float: left;
  width: calc(100% - 35px);
  padding-left: 20px;
  padding-left: 1.25rem;
}

.information_boxes__content h4 {
  line-height: 1.2em;
  color: #461540;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  font-size: 28px;
  font-size: 1.75rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.information_boxes__content p {
  color: #5a4a41;
  line-height: 1.438em;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  font-size: 16px;
  font-size: 1rem;
  font-family: 'DIN Alternate',sans-serif;
}

@media only screen and (min-width: 1200px) {
  .information_boxes__container {
    display: flex;
  }
}

.information_boxes__item_container {
  float: left;
  width: 100%;
  height: 100%;
  background: white;
  position: relative;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  box-shadow: 0px 30px 40px 0px #dbdbdb;
  -moz-box-shadow: 0px 30px 40px 0px #dbdbdb;
  -webkit-box-shadow: 0px 30px 40px 0px #dbdbdb;
}

.information_boxes__item_container:hover {
  transform: translateY(-10px);
}

.information_boxes__item_container:hover .anchor_icon {
  background: #86763f;
}

.information_boxes__item_container:hover h4 {
  color: #86763f;
}

.information_boxes__item_container:hover p {
  color: #86763f;
}

.information_boxes__item_container a {
  float: left;
  width: 100%;
  height: 100%;
  padding: 50px;
  padding: 3.125rem;
  padding-right: 85px;
  padding-right: 5.3125rem;
}

.row_articles {
  float: left;
  width: 100%;
}

.row_articles__item {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 105px;
  margin-bottom: 6.5625rem;
  padding-bottom: 110px;
  padding-bottom: 6.875rem;
  border-bottom: 1px solid #5c584d;
}

.row_articles__item:hover .row_articles__title {
  color: #461540;
}

.row_articles__item:hover p {
  color: #461540;
}

.row_articles__item:hover .row_articles__thumb img {
  transform: scale(1.05);
}

.row_articles__item:hover .view_more_button .anchor_icon {
  background: #461540;
}

.row_articles__item:hover .view_more_button span {
  color: #461540;
}

.row_articles__item--minified {
  margin-bottom: 70px;
  margin-bottom: 4.375rem;
  padding-bottom: 60px;
  padding-bottom: 3.75rem;
}

.row_articles__item--minified:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.row_articles__item--minified .row_articles__thumb {
  height: 245px;
  height: 15.3125rem;
}

.row_articles__item--minified .row_articles__content > a p {
  font-size: 16px;
  font-size: 1rem;
}

.row_articles__item--no_thumb {
  display: block;
}

.row_articles__item--no_thumb .row_articles__title {
  width: 100%;
}

.row_articles__item--no_thumb .row_articles__content {
  width: 100%;
  padding: 0 !important;
  padding-bottom: 60px !important;
  padding-bottom: 3.75rem !important;
  padding-bottom: 60px;
  padding-bottom: 3.75rem;
}

.row_articles__item--right_content {
  flex-direction: row-reverse;
}

.row_articles__item--right_content .row_articles__content {
  padding-right: 0;
  padding-left: 30px;
  padding-left: 1.875rem;
}

.row_articles__title {
  width: 100%;
  float: left;
  color: #86763f;
  line-height: 1.079em;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  font-size: 38px;
  font-size: 2.375rem;
  margin-bottom: 27px;
  margin-bottom: 1.6875rem;
}

.row_articles__content {
  float: left;
  width: 41.66667%;
  position: relative;
  padding-right: 30px;
  padding-right: 1.875rem;
  padding-bottom: 60px;
  padding-bottom: 3.75rem;
}

.row_articles__content h3 {
  margin-bottom: 45px;
  margin-bottom: 2.8125rem;
}

.row_articles__content p {
  float: left;
  width: 100%;
  color: #5a4a41;
  line-height: 1.611em;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  font-size: 18px;
  font-size: 1.125rem;
  font-family: 'DIN Alternate',sans-serif;
}

.row_articles__content .view_more_button {
  bottom: 0;
  position: absolute;
}

.row_articles__thumb {
  float: left;
  width: 58.33333%;
  height: 400px;
  height: 25rem;
}

.column_articles {
  float: left;
  width: 100%;
  margin-bottom: 55px;
  margin-bottom: 3.4375rem;
}

.column_articles--sidebar {
  background: white;
  padding: 50px 40px;
  padding: 3.125rem 2.5rem;
}

.column_articles--sidebar .column_articles__item {
  width: 100%;
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
}

.column_articles--sidebar .column_articles__item:last-child {
  margin-bottom: 0;
}

.column_articles--sidebar .column_articles__thumb {
  width: 35%;
  height: auto;
}

.column_articles--sidebar .member_info {
  width: 65%;
}

.column_articles--sidebar .member_info__name {
  font-size: 22px;
  font-size: 1.375rem;
}

.column_articles__main_title {
  float: left;
  width: 100%;
  color: #86763f;
  font-size: 38px;
  font-size: 2.375rem;
  margin-bottom: 50px;
  margin-bottom: 3.125rem;
}

.column_articles__item {
  float: left;
  width: 33.333%;
  padding: 0 25px;
  padding: 0 1.5625rem;
  margin-bottom: 25px;
  margin-bottom: 1.5625rem;
}

.column_articles__item_container {
  float: left;
  width: 100%;
}

.column_articles__item_container:hover .member_info__name {
  color: #461540;
}

.column_articles__item_container:hover .member_info__role {
  color: #461540;
}

.column_articles__item_container:hover .member_info__list li a {
  color: #461540;
}

.column_articles__thumb {
  width: 40%;
  float: left;
  text-align: center;
  max-height: 250px;
  max-height: 15.625rem;
}

.column_articles__thumb img {
  width: auto;
  max-height: 100%;
}

.member_info {
  width: 60%;
  float: left;
  padding-left: 20px;
  padding-left: 1.25rem;
}

.member_info__name {
  float: left;
  width: 100%;
  color: #86763f;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  font-size: 28px;
  font-size: 1.75rem;
  margin-bottom: 9px;
  margin-bottom: 0.5625rem;
}

.member_info__role {
  float: left;
  width: 100%;
  color: #86763f;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  font-size: 18px;
  font-size: 1.125rem;
}

.member_info__list {
  float: left;
  margin-top: 32px;
  margin-top: 2rem;
}

.member_info__list li {
  float: left;
  width: 100%;
  position: relative;
  padding-top: 10px;
  padding-top: 0.625rem;
  margin-bottom: 5px;
  margin-bottom: 0.3125rem;
}

.member_info__list li:first-child {
  padding-top: 0;
}

.member_info__list li.telephone:before {
  top: 0;
  left: 0;
  content: '';
  float: left;
  position: absolute;
  width: 22px;
  width: 1.375rem;
  height: 22px;
  height: 1.375rem;
  background: url("../images/telephone.svg") no-repeat center;
  background-size: contain;
}

.member_info__list li.mail:before {
  left: 0;
  content: '';
  float: left;
  position: absolute;
  top: 10px;
  top: 0.625rem;
  width: 22px;
  width: 1.375rem;
  height: 18px;
  height: 1.125rem;
  background: url("../images/email.svg") no-repeat center;
  background-size: contain;
}

.member_info__list li.facebook:before {
  content: '';
  float: left;
  position: absolute;
  top: 8px;
  top: 0.5rem;
  left: 4px;
  left: 0.25rem;
  width: 13px;
  width: 0.8125rem;
  height: 23px;
  height: 1.4375rem;
  background: url("../images/facebook.svg") no-repeat center;
  background-size: contain;
}

.member_info__list li a {
  position: relative;
  color: #5a4a41;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  font-size: 18px;
  font-size: 1.125rem;
  padding-left: 35px;
  padding-left: 2.1875rem;
  font-family: 'DIN Alternate',sans-serif;
}

.page_title {
  float: left;
  width: 100%;
  color: #86763f;
  font-size: 38px;
  font-size: 2.375rem;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
}

.page_desc {
  width: 70%;
  float: left;
  line-height: 1.35em;
  color: #5a4a41;
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  font-family: 'DIN Alternate',sans-serif;
}

body.activePopUp .property_popup {
  opacity: 1;
  visibility: visible;
  z-index: 5;
}

.left_side_section {
  float: left;
  width: 66.66667%;
  padding: 0 25px;
  padding: 0 1.5625rem;
}

.left_side_section .row_articles {
  margin-top: 75px;
  margin-top: 4.6875rem;
}

.right_side_section {
  float: left;
  width: 33.33333%;
  padding: 0 25px;
  padding: 0 1.5625rem;
}

.right_side_section__form {
  float: left;
  width: 100%;
  background: white;
  padding: 40px;
  padding: 2.5rem;
  margin-bottom: 50px;
  margin-bottom: 3.125rem;
}

.right_side_section__form.popup_form {
  float: left;
  width: 33.333%;
  margin-bottom: 0;
  max-width: 500px;
  max-width: 31.25rem;
  padding-bottom: 40px;
  padding-bottom: 2.5rem;
}

.right_side_section__form__title {
  color: #86763f;
  font-size: 28px;
  font-size: 1.75rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.right_side_section__form .gfield_label {
  display: none;
}

.right_side_section__form .gfield {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.right_side_section__form .gfield input {
  width: 100%;
  height: 40px;
  height: 2.5rem;
  padding: 10px;
  padding: 0.625rem;
  font-size: 16px;
  font-size: 1rem;
  border: 1px solid #5c584d;
  padding-top: 14px;
  padding-top: 0.875rem;
  font-family: 'DIN Alternate',sans-serif;
}

.right_side_section__form .gfield input::-webkit-input-placeholder {
  color: #86763f;
  padding-top: 2px;
  padding-top: 0.125rem;
  font-family: 'DIN Alternate',sans-serif;
}

.right_side_section__form .gfield input:-moz-placeholder {
  color: #86763f;
  padding-top: 2px;
  padding-top: 0.125rem;
  font-family: 'DIN Alternate',sans-serif;
}

.right_side_section__form .gfield input::-moz-placeholder {
  color: #86763f;
  padding-top: 2px;
  padding-top: 0.125rem;
  font-family: 'DIN Alternate',sans-serif;
}

.right_side_section__form .gfield input:-ms-input-placeholder {
  color: #86763f;
  padding-top: 2px;
  padding-top: 0.125rem;
  font-family: 'DIN Alternate',sans-serif;
}

.right_side_section__form .gfield textarea {
  width: 100%;
  resize: none;
  height: 170px;
  height: 10.625rem;
  font-size: 16px;
  font-size: 1rem;
  border: 1px solid #5c584d;
  padding-top: 10px;
  padding-top: 0.625rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  font-family: 'DIN Alternate',sans-serif;
}

.right_side_section__form .gfield textarea::-webkit-input-placeholder {
  color: #86763f;
  font-family: 'DIN Alternate',sans-serif;
}

.right_side_section__form .gfield textarea:-moz-placeholder {
  color: #86763f;
  font-family: 'DIN Alternate',sans-serif;
}

.right_side_section__form .gfield textarea::-moz-placeholder {
  color: #86763f;
  font-family: 'DIN Alternate',sans-serif;
}

.right_side_section__form .gfield textarea:-ms-input-placeholder {
  color: #86763f;
  font-family: 'DIN Alternate',sans-serif;
}

.right_side_section__form .gform_footer {
  float: left;
  width: 100%;
}

.right_side_section__form .gform_button {
  width: 100%;
  float: left;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: 400;
  background: #461540;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  height: 40px;
  height: 2.5rem;
  font-size: 16px;
  font-size: 1rem;
  border: 1px solid #461540;
  font-family: 'DIN Alternate',sans-serif;
}

.right_side_section__form .gform_button:hover {
  color: #461540;
  background: transparent;
}

.contact_description {
  float: left;
  width: 100%;
  background: white;
  padding: 50px;
  padding: 3.125rem;
}

.contact_description p {
  color: #5a4a41;
  line-height: 1.556em;
  font-size: 18px;
  font-size: 1.125rem;
}

.contact_list {
  width: 100%;
  float: left;
  padding: 0 25px;
  padding: 0 1.5625rem;
}

.contact_list ul {
  float: left;
  width: 100%;
  margin-top: 37px;
  margin-top: 2.3125rem;
}

.contact_list ul li {
  float: left;
  width: 100%;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

.contact_list ul li:last-child {
  margin-bottom: 0;
}

.contact_list ul a {
  color: #86763f;
  text-decoration: none;
}

.contact_list ul span {
  color: #86763f;
  font-size: 28px;
  font-size: 1.75rem;
}

.search_box {
  float: left;
  width: 100%;
  margin-top: 63px;
  margin-top: 3.9375rem;
  margin-bottom: 115px;
  margin-bottom: 7.1875rem;
  padding-bottom: 115px;
  padding-bottom: 7.1875rem;
  border-bottom: 1px solid #5c584d;
}

.search_box__container {
  width: 100%;
  float: left;
  background: white;
}

.search_box__top_header {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
}

.search_box__header {
  float: left;
  width: 100%;
  padding: 25px 30px 0;
  padding: 1.5625rem 1.875rem 0;
}

.search_box__result_number {
  float: left;
  color: #707070;
  font-size: 20px;
  font-size: 1.25rem;
  font-family: 'DIN Alternate',sans-serif;
}

.search_box__sorting {
  width: auto;
  float: right;
}

.search_box__sorting h4 {
  color: #707070;
  display: inline-block;
  font-size: 20px;
  font-size: 1.25rem;
  padding-right: 5px;
  padding-right: 0.3125rem;
  font-family: 'DIN Alternate',sans-serif;
}

.search_box__sorting select {
  outline: none;
  border-radius: 6px;
  color: #707070;
  display: inline-block;
  padding: 10px;
  padding: 0.625rem;
  font-size: 18px;
  font-size: 1.125rem;
  border: 1px solid #707070;
  padding-left: 5px;
  padding-left: 0.3125rem;
  padding-right: 20px;
  padding-right: 1.25rem;
  font-family: 'DIN Alternate',sans-serif;
}

.search_box__input {
  float: left;
  width: 100%;
  position: relative;
  height: 60px;
  height: 3.75rem;
  padding: 10px;
  padding: 0.625rem;
  border: 1px solid #707070;
  border-radius: 4px;
  border-radius: 0.25rem;
  padding-left: 45px;
  padding-left: 2.8125rem;
}

.search_box__input input {
  float: left;
  width: 100%;
  border: none;
  background: transparent;
}

.search_box__input input[type="submit"] {
  top: 50%;
  opacity: 0;
  float: left;
  position: absolute;
  left: 11px;
  left: 0.6875rem;
  width: 25px;
  width: 1.5625rem;
  height: 25px;
  height: 1.5625rem;
  transform: translateY(-50%);
}

.search_box__input input[type="text"] {
  float: left;
  height: 100%;
  width: calc(100% - 25px);
}

.search_box__input input[type="text"]::-webkit-input-placeholder {
  font-size: 22px;
  font-size: 1.375rem;
  font-family: 'DIN Alternate',sans-serif;
}

.search_box__input input[type="text"]:-moz-placeholder {
  font-size: 22px;
  font-size: 1.375rem;
  font-family: 'DIN Alternate',sans-serif;
}

.search_box__input input[type="text"]::-moz-placeholder {
  font-size: 22px;
  font-size: 1.375rem;
  font-family: 'DIN Alternate',sans-serif;
}

.search_box__input input[type="text"]:-ms-input-placeholder {
  font-size: 22px;
  font-size: 1.375rem;
  font-family: 'DIN Alternate',sans-serif;
}

.search_box__input .submit_button {
  top: 50%;
  position: absolute;
  left: 11px;
  left: 0.6875rem;
  width: 25px;
  width: 1.5625rem;
  height: 25px;
  height: 1.5625rem;
  transform: translateY(-50%);
  background: url("../images/search.svg") no-repeat center;
  background-size: 20px;
  background-size: 1.25rem;
}

.search_box__results {
  float: left;
  width: 100%;
  background: white;
  padding-top: 15px;
  padding-top: 0.9375rem;
}

.search_box__item {
  float: left;
  width: 100%;
  padding: 30px;
  padding: 1.875rem;
  border-bottom: 1px solid #5c584d;
}

.search_box__thumb {
  float: left;
  width: 33.33333%;
  height: 245px;
  height: 15.3125rem;
}

.search_box__content {
  float: left;
  color: black;
  width: 66.66667%;
  margin-top: 10px;
  margin-top: 0.625rem;
  padding-left: 30px;
  padding-left: 1.875rem;
  font-family: 'DIN Alternate',sans-serif;
}

.search_box__location {
  float: left;
  width: 100%;
  line-height: 1.2em;
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 18px;
  margin-bottom: 1.125rem;
}

.search_box__location_name {
  float: left;
  width: 100%;
  line-height: 1.25em;
  font-size: 24px;
  font-size: 1.5rem;
  margin-bottom: 19px;
  margin-bottom: 1.1875rem;
}

.search_box__specs {
  float: left;
  width: 100%;
  margin-bottom: 19px;
  margin-bottom: 1.1875rem;
}

.search_box__specs li {
  float: left;
  display: inline-block;
}

.search_box__specs li:last-child span {
  margin-right: 0;
}

.search_box__specs span {
  float: left;
  font-weight: bold;
  font-size: 22px;
  font-size: 1.375rem;
  margin-right: 30px;
  margin-right: 1.875rem;
}

.search_box__other {
  float: left;
  width: 100%;
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}

.search_box__other:last-child {
  margin-bottom: 0;
}

.search_box__footer {
  float: left;
  width: 100%;
  text-align: center;
  padding: 30px 0;
  padding: 1.875rem 0;
}

.map_section {
  float: left;
  width: 100%;
  margin-top: 62px;
  margin-top: 3.875rem;
  margin-bottom: 80px;
  margin-bottom: 5rem;
}

.map_section__map_locations {
  float: left;
  width: 100%;
  background: #86763f;
  height: 710px;
  height: 44.375rem;
  margin-bottom: 60px;
  margin-bottom: 3.75rem;
}

.map_section__legend {
  float: left;
  width: 100%;
}

.map_section__legend_button {
  float: left;
  color: white;
  max-width: 30%;
  text-align: center;
  text-decoration: none;
  background: #461540;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  min-width: 350px;
  min-width: 21.875rem;
  font-size: 24px;
  font-size: 1.5rem;
  border: 1px solid #461540;
  padding: 10px 30px;
  padding: 0.625rem 1.875rem;
}

.map_section__legend_button:hover {
  color: #461540;
  background: transparent;
}

.map_section__legend_list {
  width: auto;
  float: right;
}

.map_section__legend_list li {
  float: left;
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-right: 1.875rem;
}

.map_section__legend_list li:last-child {
  margin-right: 0;
}

.map_section__legend_list li p {
  float: left;
  color: #5a4a41;
  font-size: 18px;
  font-size: 1.125rem;
  margin-left: 10px;
  margin-left: 0.625rem;
  font-family: 'DIN Alternate',sans-serif;
}

.map_section__icon {
  float: left;
  width: 33px;
  width: 2.0625rem;
  height: 43px;
  height: 2.6875rem;
}

.map_section__icon.car {
  background: url("../images/map-car-active.svg") no-repeat center;
  background-size: contain;
}

.map_section__icon.car--not_active {
  background: url("../images/map-car-not-active.svg") no-repeat center;
  background-size: contain;
}

.map_section__icon.appartment {
  background: url("../images/map-appartment-active.svg") no-repeat center;
  background-size: contain;
}

.map_section__icon.appartment--not_active {
  background: url("../images/map-appartment-not-active.svg") no-repeat center;
  background-size: contain;
}

.map_section .gmnoprint:not(.gm-bundled-control) {
  display: none;
}

.map_section .gmnoprint .gmnoprint {
  display: block;
}

.locations_section {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 80px;
  margin-bottom: 5rem;
}

.locations_section:last-child {
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
  padding-bottom: 80px;
  padding-bottom: 5rem;
}

.locations_section:last-child:after {
  left: 50%;
  bottom: 0;
  content: '';
  width: 85.4%;
  position: absolute;
  background: #5c584d;
  height: 1px;
  height: 0.0625rem;
  transform: translateX(-50%);
}

.locations_section__header {
  float: left;
  width: 100%;
  display: flex;
  padding: 0 7.3%;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 280px;
  min-height: 17.5rem;
  padding-top: 40px;
  padding-top: 2.5rem;
  margin-bottom: 5px;
  margin-bottom: 0.3125rem;
  padding-bottom: 40px;
  padding-bottom: 2.5rem;
  background: url("../images/picture-1.jpg") no-repeat center;
  background-size: cover;
}

.locations_section__header:before {
  top: 0;
  left: 0;
  z-index: 2;
  content: '';
  width: 100%;
  opacity: 0.4;
  height: 100%;
  background: black;
  position: absolute;
}

.locations_section__header h4 {
  z-index: 3;
  color: white;
  line-height: 1.2em;
  position: relative;
  font-size: 38px;
  font-size: 2.375rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.locations_section__header p {
  z-index: 3;
  width: 60%;
  color: white;
  position: relative;
  line-height: 1.3em;
  font-size: 18px;
  font-size: 1.125rem;
  font-family: 'DIN Alternate',sans-serif;
}

.locations_section__popup {
  float: left;
  width: 100%;
}

.locations_section__popup.onlyChild {
  min-width: 250px;
  min-width: 15.625rem;
}

.locations_section__popup.onlyChild .locations_section__item {
  width: 100% !important;
}

.locations_section__popup.twoChildren .locations_section__item {
  width: 50% !important;
}

.locations_section__popup .locations_section__item {
  float: left;
  width: 33.333% !important;
  padding: 0 10px;
  padding: 0 0.625rem;
  margin-top: 20px;
  margin-top: 1.25rem;
  min-height: 180px;
  min-height: 11.25rem;
}

.locations_section__popup .locations_section__item--disabled .locations_section__item_header {
  color: #86763f;
}

.locations_section__popup .locations_section__item_header {
  color: #461540;
  font-size: 20px;
  font-size: 1.25rem;
}

.locations_section__popup .locations_section__button {
  padding: 0 10px;
  padding: 0 0.625rem;
}

.locations_section__popup .locations_section__inner_list {
  padding-bottom: 65px;
  padding-bottom: 4.0625rem;
}

.locations_section__popup .locations_section__inner_list li span {
  font-size: 15px;
  font-size: 0.9375rem;
}

.locations_section__list {
  float: left;
  width: 100%;
  padding: 0 7.3%;
}

.locations_section__list_container {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.locations_section__item {
  float: left;
  width: 16.66667%;
  position: relative;
  padding: 0 25px;
  padding: 0 1.5625rem;
  margin-top: 50px;
  margin-top: 3.125rem;
  min-height: 250px;
  min-height: 15.625rem;
  padding-bottom: 70px;
  padding-bottom: 4.375rem;
}

.locations_section__item_header {
  line-height: 1.2em;
  color: #86763f;
  border-bottom: 1px solid;
  font-size: 20px;
  font-size: 1.25rem;
  padding-bottom: 2px;
  padding-bottom: 0.125rem;
}

.locations_section__inner_list {
  float: left;
  width: 100%;
}

.locations_section__inner_list li {
  float: left;
  width: 100%;
  margin-top: 12px;
  margin-top: 0.75rem;
}

.locations_section__inner_list li span {
  font-size: 18px;
  font-size: 1.125rem;
  font-family: 'DIN Alternate',sans-serif;
}

.locations_section__button {
  left: 0;
  bottom: 0;
  float: left;
  width: 100%;
  position: absolute;
  padding: 0 25px;
  padding: 0 1.5625rem;
}

.locations_section__button a {
  float: left;
  width: 100%;
  border: 1px solid;
  position: relative;
  text-align: center;
  color: #461540;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  height: 30px;
  height: 1.875rem;
  padding: 5px;
  padding: 0.3125rem;
  font-size: 16px;
  font-size: 1rem;
  font-family: 'DIN Alternate',sans-serif;
}

.locations_section__button a:after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  color: white;
  height: 100%;
  position: absolute;
  visibility: hidden;
  background: #461540;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  padding: 5px;
  padding: 0.3125rem;
  content: attr(data-hover-text);
}

.locations_section__button a:hover:after {
  opacity: 1;
  visibility: visible;
}

.locations_section__button--disabled a {
  width: auto;
  border: none;
  height: auto;
  padding-top: 0;
  padding-left: 0;
  text-align: left;
  color: #5c584d;
  cursor: context-menu;
}

.locations_section__button--disabled a:after {
  display: none;
}

#map {
  float: left;
  width: 100%;
  height: 100%;
}

.ie #map {
  display: table-cell;
}

.gform_confirmation_message {
  line-height: 1.2em;
  text-align: center;
  color: #5a4a41;
  font-size: 20px;
  font-size: 1.25rem;
}

.validation_error {
  text-align: center;
  line-height: 1.2em;
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}

.validation_message {
  color: #d91200;
  line-height: 1.2em;
  font-size: 16px;
  font-size: 1rem;
  margin-top: 5px;
  margin-top: 0.3125rem;
}

.property_popup {
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  float: left;
  width: 100%;
  height: 100%;
  position: fixed;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.7);
  padding: 80px 70px;
  padding: 5rem 4.375rem;
}

.property_popup__container {
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.property_popup__close_button {
  top: 10%;
  z-index: 5;
  cursor: pointer;
  position: absolute;
  right: 60px;
  right: 3.75rem;
  width: 20px;
  width: 1.25rem;
  height: 20px;
  height: 1.25rem;
  background: url("../images/close.svg") no-repeat center;
  background-size: cover;
}

.error404 .main_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.error_page {
  float: left;
  width: 100%;
}

.error_page__title {
  text-align: center;
  color: #461540;
  font-size: 110px;
  font-size: 6.875rem;
}

.error_page__desc {
  text-align: center;
  color: #86763f;
  line-height: 1.3em;
  font-size: 30px;
  font-size: 1.875rem;
}

.slideshow_section {
  top: 0;
  left: 0;
  z-index: 1;
  float: left;
  width: 100%;
  height: 100%;
  position: absolute;
}

.slideshow_section__thumb {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  visibility: hidden;
  position: absolute;
  opacity: 0 !important;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.slideshow_section__thumb.active {
  visibility: visible;
  opacity: 1 !important;
}

.slideshow_section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cookie_popup {
  left: 0;
  bottom: 0;
  opacity: 0;
  z-index: 15;
  width: 100%;
  position: fixed;
  background: white;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transform: translateY(0);
  transform: translateY(100%);
  height: 60px;
  height: 3.75rem;
  padding-top: 11px;
  padding-top: 0.6875rem;
}

.cookie_popup.active {
  opacity: 1;
  transform: translateY(0);
}

.cookie_popup p {
  float: left;
  font-size: 18px;
  font-size: 1.125rem;
  margin-top: 11px;
  margin-top: 0.6875rem;
  margin-right: 15px;
  margin-right: 0.9375rem;
}

.cookie_buttons {
  float: right;
}

.cookie_buttons a {
  float: left;
  color: #5a4a41;
  text-decoration: none;
  padding: 10px;
  padding: 0.625rem;
  font-size: 15px;
  font-size: 0.9375rem;
  margin-top: 3px;
  margin-top: 0.1875rem;
  margin-right: 15px;
  margin-right: 0.9375rem;
  border-radius: 20px;
  border-radius: 1.25rem;
  font-family: 'DIN Alternate',sans-serif;
}

.cookie_buttons a.accept_cookie {
  color: white;
  margin-right: 0;
  background: #5a4a41;
  padding: 10px 20px;
  padding: 0.625rem 1.25rem;
}

.article_content {
  float: left;
  width: 100%;
  line-height: 1.7em;
  padding: 40px 0;
  padding: 2.5rem 0;
  font-size: 16px;
  font-size: 1rem;
}

.article_content h1,
.article_content h2 {
  float: left;
  width: 100%;
  font-weight: 500;
  line-height: 1.2em;
  color: #5a4a41;
  font-size: 32px;
  font-size: 2rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.article_content h3,
.article_content h4 {
  float: left;
  width: 100%;
  font-weight: 500;
  line-height: 1.2em;
  color: #5a4a41;
  font-size: 26px;
  font-size: 1.625rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.article_content h5,
.article_content h6 {
  float: left;
  width: 100%;
  font-weight: 500;
  line-height: 1.2em;
  color: #5a4a41;
  font-size: 22px;
  font-size: 1.375rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.article_content p {
  line-height: 1.7em;
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.article_content strong {
  line-height: 1.7em;
  margin: 0 5px;
  margin: 0 0.3125rem;
  font-size: 16px;
  font-size: 1rem;
}

.article_content em {
  line-height: 1.7em;
  margin: 0 5px;
  margin: 0 0.3125rem;
  font-size: 16px;
  font-size: 1rem;
}

.article_content ul {
  margin: 10px 0;
  margin: 0.625rem 0;
  padding-left: 15px;
  padding-left: 0.9375rem;
}

.article_content ul li {
  list-style-type: disc;
}

.article_content ul li ul {
  margin: 3px 0;
  margin: 0.1875rem 0;
}

.article_content ul li ul li {
  list-style-type: circle;
}

.article_content ol {
  margin: 10px 0;
  margin: 0.625rem 0;
  padding-left: 15px;
  padding-left: 0.9375rem;
}

.article_content ol li {
  list-style-type: decimal;
}

.article_content ol li ul {
  margin: 3px 0;
  margin: 0.1875rem 0;
}

.article_content ol li ul li {
  list-style-type: circle;
}

.article_content a {
  outline: 0;
  color: #0089e5;
  line-height: 1.7em;
  text-decoration: none;
  font-size: 16px;
  font-size: 1rem;
}

.article_content blockquote {
  float: left;
  width: 100%;
  color: black;
  text-align: center;
  font-style: italic;
  line-height: 1.7em;
  background: #86763f;
  padding: 20px;
  padding: 1.25rem;
  margin: 10px 0;
  margin: 0.625rem 0;
  font-size: 18px;
  font-size: 1.125rem;
}

.article_content img {
  margin-top: 10px;
  margin-top: 0.625rem;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}

.article_content img.alignnone {
  width: 100%;
}

.footer_container {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 80px;
  margin-top: 5rem;
  margin-bottom: 73px;
  margin-bottom: 4.5625rem;
}

.footer_logo {
  margin-bottom: 28px;
  margin-bottom: 1.75rem;
}

.footer_logo img {
  height: 75px;
  height: 4.6875rem;
}

.footer_text {
  float: left;
  color: #5c584d;
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 13px;
  margin-bottom: 0.8125rem;
}

.footer_text a {
  color: #5c584d;
  text-decoration: none;
}

.footer_text a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .first_section {
    height: auto;
  }
  .first_section__content {
    top: auto;
    left: auto;
    width: 100%;
    transform: none;
    position: relative;
    font-size: 26px;
    font-size: 1.625rem;
    padding: 60px 15px;
    padding: 3.75rem 0.9375rem;
  }
  .slideshow_main__item {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .slideshow_main__text {
    top: auto;
    left: auto;
    width: 100%;
    transform: none;
    position: relative;
    font-size: 26px;
    font-size: 1.625rem;
    padding: 60px 15px;
    padding: 3.75rem 0.9375rem;
  }
  .slideshow_main .owl-stage {
    display: flex;
  }
  .slideshow_main .owl-item {
    flex: 1;
    height: auto;
    min-width: 100vw;
  }
  .information_boxes {
    margin-top: 15px;
    margin-top: 0.9375rem;
    margin-bottom: 15px;
    margin-bottom: 0.9375rem;
  }
  .information_boxes__item {
    width: 100%;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .information_boxes__content h4 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .information_boxes__content p {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .information_boxes__item_container a {
    padding: 20px;
    padding: 1.25rem;
  }
  .view_more_button span {
    font-size: 20px;
    font-size: 1.25rem;
    padding-left: 15px;
    padding-left: 0.9375rem;
  }
  .main_section--archive_container {
    margin-top: 30px;
    margin-top: 1.875rem;
  }
  .page_title {
    font-size: 28px;
    font-size: 1.75rem;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .page_desc {
    width: 100%;
    font-size: 15px;
    font-size: 0.9375rem;
  }
  .left_side_section {
    width: 100%;
  }
  .left_side_section .row_articles {
    margin-top: 40px;
    margin-top: 2.5rem;
  }
  .right_side_section {
    width: 100%;
  }
  .right_side_section__form {
    padding: 20px;
    padding: 1.25rem;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .contact_description {
    padding: 20px;
    padding: 1.25rem;
  }
  .contact_description p {
    font-size: 16px;
    font-size: 1rem;
  }
  .contact_description ul {
    margin-top: 20px;
    margin-top: 1.25rem;
  }
  .contact_description ul li {
    margin-bottom: 15px;
    margin-bottom: 0.9375rem;
  }
  .contact_description ul span {
    font-size: 22px;
    font-size: 1.375rem;
  }
  .anchor_icon {
    width: 30px;
    width: 1.875rem;
    height: 30px;
    height: 1.875rem;
  }
  .row_articles__item {
    display: block;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .row_articles__item--no_thumb .row_articles__content {
    margin-bottom: 0;
    padding-bottom: 0 !important;
    padding-bottom: 0 !important;
    padding-bottom: 0;
    padding-bottom: 0;
  }
  .row_articles__thumb {
    width: 100%;
    height: 220px;
    height: 13.75rem;
  }
  .row_articles__title {
    font-size: 24px;
    font-size: 1.5rem;
    margin-bottom: 20px !important;
    margin-bottom: 1.25rem !important;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .row_articles__content {
    width: 100%;
    padding: 0 !important;
    margin-bottom: 15px;
    margin-bottom: 0.9375rem;
  }
  .row_articles__content .view_more_button {
    position: relative;
    margin-top: 10px;
    margin-top: 0.625rem;
  }
  .row_articles__content p {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .column_articles {
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .column_articles--sidebar {
    padding: 20px;
    padding: 1.25rem;
  }
  .column_articles--sidebar .column_articles__item {
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .column_articles__item {
    width: 100%;
  }
  .column_articles__item:last-child .member_info {
    margin-bottom: 0;
  }
  .column_articles__main_title {
    font-size: 26px;
    font-size: 1.625rem;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .member_info {
    margin: 0;
    padding-left: 15px;
    padding-left: 0.9375rem;
  }
  .member_info__list {
    margin-top: 15px;
    margin-top: 0.9375rem;
  }
  .member_info__list li a {
    font-size: 16px;
    font-size: 1rem;
  }
  .member_info__list li.telephone:before {
    left: 4px;
    left: 0.25rem;
    width: 17px;
    width: 1.0625rem;
    height: 17px;
    height: 1.0625rem;
  }
  .member_info__list li.mail:before {
    left: 3px;
    left: 0.1875rem;
    width: 19px;
    width: 1.1875rem;
  }
  .member_info__list li.facebook:before {
    left: 7px;
    left: 0.4375rem;
    width: 11px;
    width: 0.6875rem;
    height: 20px;
    height: 1.25rem;
  }
  .member_info__name {
    font-size: 24px;
    font-size: 1.5rem;
    margin-bottom: 5px;
    margin-bottom: 0.3125rem;
  }
  .member_info__role {
    font-size: 16px;
    font-size: 1rem;
  }
  .search_box {
    margin-bottom: 30px;
    margin-bottom: 1.875rem;
    padding-bottom: 30px;
    padding-bottom: 1.875rem;
  }
  .search_box__header {
    display: none;
  }
  .search_box__item {
    padding: 20px;
    padding: 1.25rem;
  }
  .search_box__thumb {
    width: 100%;
    height: 200px;
    height: 12.5rem;
  }
  .search_box__content {
    float: left;
    width: 100%;
    padding-left: 0;
  }
  .search_box__location {
    font-size: 19px;
    font-size: 1.1875rem;
    margin-bottom: 10px;
    margin-bottom: 0.625rem;
  }
  .search_box__location_name {
    font-size: 17px;
    font-size: 1.0625rem;
    margin-bottom: 10px;
    margin-bottom: 0.625rem;
  }
  .search_box__specs {
    margin-bottom: 10px;
    margin-bottom: 0.625rem;
  }
  .search_box__specs span {
    font-size: 18px;
    font-size: 1.125rem;
    margin-right: 15px;
    margin-right: 0.9375rem;
  }
  .search_box__other {
    font-size: 16px;
    font-size: 1rem;
    margin-bottom: 5px;
    margin-bottom: 0.3125rem;
  }
  .search_box__footer {
    padding: 15px 0;
    padding: 0.9375rem 0;
  }
  .search_box__logo img {
    height: 40px;
    height: 2.5rem;
  }
  .gm-style .gm-style-iw-c {
    padding-left: 0;
  }
  .locations_section {
    margin-bottom: 30px;
    margin-bottom: 1.875rem;
  }
  .locations_section:last-child {
    margin-bottom: 0;
    padding-bottom: 30px;
    padding-bottom: 1.875rem;
  }
  .locations_section:last-child:after {
    width: 100%;
  }
  .locations_section__popup.onlyChild {
    min-width: auto;
  }
  .locations_section__popup .locations_section__inner_list {
    padding-bottom: 45px;
  }
  .locations_section__popup .locations_section__button a {
    height: auto;
    font-size: 14px;
    font-size: 0.875rem;
  }
  .locations_section__popup .locations_section__item {
    width: 100% !important;
    min-height: auto;
  }
  .locations_section__popup .locations_section__item:first-child {
    margin-top: 0;
  }
  .locations_section__popup .locations_section__item_header {
    font-size: 16px;
    font-size: 1rem;
  }
  .locations_section__popup .locations_section__inner_list li {
    margin-top: 10px;
    margin-top: 0.625rem;
  }
  .locations_section__popup .locations_section__inner_list span {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .locations_section__header {
    display: block;
    min-height: auto;
    padding: 25px 10px;
    padding: 1.5625rem 0.625rem;
  }
  .locations_section__header h4 {
    font-size: 32px;
    font-size: 2rem;
  }
  .locations_section__header p {
    width: 100%;
    font-size: 16px;
    font-size: 1rem;
  }
  .locations_section__item {
    width: 100%;
    min-height: auto;
    padding-left: 0;
    padding-left: 0;
    padding-right: 0;
    padding-right: 0;
    margin-top: 20px;
    margin-top: 1.25rem;
    padding-bottom: 50px;
    padding-bottom: 3.125rem;
  }
  .locations_section__button {
    padding: 0;
  }
  .map_section {
    margin-top: 0;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .map_section__map_locations {
    height: 400px;
    height: 25rem;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .map_section__icon {
    width: 25px;
    width: 1.5625rem;
    height: 33px;
    height: 2.0625rem;
  }
  .map_section__legend_button {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-size: 1.125rem;
  }
  .map_section__legend_list {
    margin-top: 10px;
    margin-top: 0.625rem;
  }
  .map_section__legend_list li {
    width: 50%;
    margin-right: 0;
    margin-top: 10px;
    margin-top: 0.625rem;
    padding-right: 15px;
    padding-right: 0.9375rem;
  }
  .map_section__legend_list li p {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .cookie_popup {
    height: auto;
  }
  .cookie_popup p {
    float: left;
    width: 100%;
    line-height: 1.3em;
    margin-top: 5px;
    margin-top: 0.3125rem;
  }
  .cookie_buttons {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 5px;
    margin-top: 0.3125rem;
    margin-bottom: 5px;
    margin-bottom: 0.3125rem;
  }
  .cookie_buttons a {
    float: none;
    display: inline-block;
  }
}

@media only screen and (max-width: 1024px) {
  .extended_container--header {
    float: left;
    height: auto;
    display: block;
    padding: 20px 15px;
    padding: 1.25rem 0.9375rem;
  }
  .mid_container {
    float: left;
    padding: 0 15px;
    padding: 0 0.9375rem;
  }
  .header_navigation li a {
    line-height: 1.4em;
    font-size: 18px;
    font-size: 1.125rem;
    padding: 7px 10px 0;
    padding: 0.4375rem 0.625rem 0;
  }
  .activeNav .hamburger {
    position: fixed;
    right: 15px;
    right: 0.9375rem;
  }
  .activeNav .header_navigation {
    opacity: 1;
    visibility: visible;
  }
  .activeNav .lang_switcher {
    margin-right: 55px;
  }
  .lang_switcher {
    float: right;
    display: block;
    margin-top: 17px;
    margin-right: 25px;
  }
  .header_navigation {
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 5;
    width: 100%;
    height: 100vh;
    position: fixed;
    visibility: hidden;
    background: rgba(255, 255, 255, 0.9);
  }
  .header_navigation .lang_switcher {
    text-align: center;
    margin-top: 20px;
    margin-top: 1.25rem;
  }
  .header_navigation li {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .header_navigation__container {
    top: 50%;
    left: 50%;
    width: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  .header_navigation .lang_switcher {
    display: block;
  }
  .mainWrap {
    overflow: hidden;
  }
  .main_section .extended_container {
    padding: 0;
  }
  .information_boxes__item_container {
    border-radius: 2px;
    box-shadow: 0 0 40px 0 #dbdbdb;
    -webkit-box-shadow: 0 0 40px 0 #dbdbdb;
  }
  .footer_container {
    display: block;
    text-align: center;
    margin-top: 20px;
    margin-top: 1.25rem;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .footer_text {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    font-size: 15px;
    font-size: 0.9375rem;
    margin-top: 10px;
    margin-top: 0.625rem;
  }
  .hamburger {
    display: block;
  }
  .footer_logo {
    margin-bottom: 8px;
    margin-bottom: 0.5rem;
  }
  .footer_logo img {
    height: 55px;
    height: 3.4375rem;
  }
  .logo {
    transform: none;
    position: static;
  }
  .logo img {
    height: 50px;
    height: 3.125rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .information_boxes {
    margin-bottom: 50px;
    margin-bottom: 3.125rem;
  }
  .information_boxes__item {
    width: 100%;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .information_boxes__item:last-child {
    margin-bottom: 0;
  }
  .first_section {
    height: 50vh;
  }
  .first_section__content {
    width: 100%;
    font-size: 46px;
    font-size: 2.875rem;
    padding: 0 20px;
    padding: 0 1.25rem;
  }
  .slideshow_main {
    height: 50vh;
  }
  .slideshow_main__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .slideshow_main__text {
    width: 100%;
    font-size: 46px;
    font-size: 2.875rem;
    padding: 0 20px;
    padding: 0 1.25rem;
  }
  .slideshow_main .owl-stage {
    display: flex;
  }
  .slideshow_main .owl-item {
    flex: 1;
    height: 50vh;
    min-width: 100vw;
  }
  .row_articles__title {
    font-size: 30px;
    font-size: 1.875rem;
    margin-bottom: 25px;
    margin-bottom: 1.5625rem;
  }
  .row_articles__content {
    width: 45%;
  }
  .row_articles__content p {
    font-size: 16px;
    font-size: 1rem;
    padding-top: 11px;
    padding-top: 0.6875rem;
  }
  .row_articles__content .view_more_button {
    position: relative;
    margin-top: 20px;
    margin-top: 1.25rem;
  }
  .row_articles__thumb {
    width: 55%;
  }
  .row_articles__item {
    margin-bottom: 30px;
    margin-bottom: 1.875rem;
    padding-bottom: 30px;
    padding-bottom: 1.875rem;
  }
  .view_more_button span {
    font-size: 20px;
    font-size: 1.25rem;
    padding-top: 10px;
    padding-top: 0.625rem;
  }
  .main_section--archive_container {
    margin-top: 30px;
    margin-top: 1.875rem;
  }
  .page_desc {
    width: 100%;
  }
  .column_articles--sidebar {
    padding-bottom: 0;
    padding: 30px;
    padding: 1.875rem;
  }
  .column_articles--sidebar .column_articles__item {
    width: 50%;
  }
  .column_articles__items .row {
    margin-left: -15px;
    margin-right: -15px;
  }
  .column_articles__item {
    width: 50%;
    clear: left;
    margin-bottom: 0;
    padding: 0 15px;
    padding: 0 0.9375rem;
    margin-top: 25px;
    margin-top: 1.5625rem;
  }
  .column_articles__item:nth-child(2n) {
    clear: none;
  }
  .column_articles__item:first-child, .column_articles__item:nth-child(2) {
    margin: 0;
  }
  .column_articles__main_title {
    font-size: 30px;
    font-size: 1.875rem;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .search_box {
    margin-bottom: 30px;
    margin-bottom: 1.875rem;
    padding-bottom: 30px;
    padding-bottom: 1.875rem;
  }
  .search_box__thumb {
    width: 50%;
    height: 220px;
    height: 13.75rem;
  }
  .search_box__content {
    width: 50%;
  }
  .search_box__location {
    font-size: 16px;
    font-size: 1rem;
    margin-bottom: 10px;
    margin-bottom: 0.625rem;
  }
  .search_box__location_name {
    font-size: 18px;
    font-size: 1.125rem;
    margin-bottom: 10px;
    margin-bottom: 0.625rem;
  }
  .search_box__other {
    font-size: 16px;
    font-size: 1rem;
  }
  .search_box__specs {
    margin-bottom: 15px;
    margin-bottom: 0.9375rem;
  }
  .search_box__specs span {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .search_box__footer {
    padding: 15px 0;
    padding: 0.9375rem 0;
  }
  .search_box__logo img {
    height: 40px;
    height: 2.5rem;
  }
  .left_side_section {
    width: 100%;
  }
  .left_side_section .row_articles {
    margin-top: 30px;
    margin-top: 1.875rem;
  }
  .right_side_section {
    width: 100%;
  }
  .right_side_section__form {
    padding: 30px;
    padding: 1.875rem;
    margin-bottom: 30px;
    margin-bottom: 1.875rem;
  }
  .contact_description {
    padding: 20px;
    padding: 1.25rem;
  }
  .contact_description ul {
    margin-top: 20px;
    margin-top: 1.25rem;
  }
  .contact_description ul li {
    margin-bottom: 15px;
    margin-bottom: 0.9375rem;
  }
  .contact_description ul span {
    font-size: 22px;
    font-size: 1.375rem;
  }
  .member_info__list {
    margin-top: 20px;
    margin-top: 1.25rem;
  }
  .member_info__list li a {
    font-size: 16px;
    font-size: 1rem;
  }
  .member_info__list li:before {
    left: 3px !important;
    left: 0.1875rem !important;
    left: 3px;
    left: 0.1875rem;
    width: 18px !important;
    width: 1.125rem !important;
    width: 18px;
    width: 1.125rem;
    height: 18px !important;
    height: 1.125rem !important;
    height: 18px;
    height: 1.125rem;
  }
  .member_info__name {
    font-size: 22px;
    font-size: 1.375rem;
  }
  .member_info__role {
    font-size: 16px;
    font-size: 1rem;
  }
  .map_section {
    margin-top: 20px;
    margin-top: 1.25rem;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .map_section__legend_button {
    font-size: 20px;
    font-size: 1.25rem;
    padding: 10px 50px;
    padding: 0.625rem 3.125rem;
    margin-bottom: 10px;
    margin-bottom: 0.625rem;
  }
  .map_section__map_locations {
    height: 450px;
    height: 28.125rem;
    margin-bottom: 30px;
    margin-bottom: 1.875rem;
  }
  .map_section__legend_list {
    width: 100%;
  }
  .map_section__legend_list li {
    width: 25%;
    margin-right: 0;
    margin-top: 20px;
    margin-top: 1.25rem;
    padding-right: 10px;
    padding-right: 0.625rem;
  }
  .map_section__icon {
    width: 26px;
    width: 1.625rem;
    height: 34px;
    height: 2.125rem;
  }
  .locations_section {
    margin-bottom: 40px;
    margin-bottom: 2.5rem;
  }
  .locations_section:last-child:after {
    width: 100%;
  }
  .locations_section__header {
    display: block;
    min-height: auto;
    padding: 40px;
    padding: 2.5rem;
  }
  .locations_section__header h4 {
    font-size: 32px;
    font-size: 2rem;
  }
  .locations_section__header p {
    width: 80%;
    font-size: 16px;
    font-size: 1rem;
  }
  .locations_section__item {
    float: left;
    width: 33.3333%;
    padding: 0 15px;
    padding: 0 0.9375rem;
    margin-top: 30px;
    margin-top: 1.875rem;
  }
  .locations_section__list {
    padding: 0 12px;
    padding: 0 0.75rem;
  }
  .locations_section__button a {
    padding-top: 8px;
    padding-top: 0.5rem;
  }
  .cookie_popup {
    height: auto;
    padding-bottom: 10px;
    padding-bottom: 0.625rem;
  }
  .cookie_popup p {
    width: 60%;
    margin-right: 0;
    line-height: 1.3em;
    padding-right: 10px;
    padding-right: 0.625rem;
  }
  .cookie_buttons {
    margin-top: 10px;
    margin-top: 0.625rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  html {
    font-size: 70%;
  }
  html:not(.mac) .row_articles__content p {
    font-family: 'Arial';
  }
  html:not(.mac) .information_boxes__content p {
    font-family: 'Arial';
  }
  html:not(.mac) .locations_section__item {
    width: 25%;
  }
  html:not(.mac) .locations_section__item_header {
    font-family: 'Arial';
  }
  html:not(.mac) .locations_section__inner_list li span {
    font-family: 'Arial';
    font-size: 20px;
    font-size: 1.25rem;
    letter-spacing: 1px;
    letter-spacing: 0.0625rem;
  }
  html:not(.mac) .locations_section__button a {
    font-family: 'Arial';
    padding-top: 6px;
    padding-top: 0.375rem;
    font-size: 17px;
    font-size: 1.0625rem;
  }
  html:not(.mac) .locations_section__button a:after {
    font-family: 'Arial';
    padding-top: 6px;
    padding-top: 0.375rem;
  }
  html:not(.mac) .member_info__role {
    font-family: 'Arial';
  }
  html:not(.mac) .contact_description p {
    font-family: 'Arial';
  }
  html:not(.mac) .header_navigation li a {
    font-family: 'Arial';
  }
  html:not(.mac) .page_desc {
    font-family: 'Arial';
  }
  html:not(.mac) .locations_section__header p {
    font-family: 'Arial';
  }
  html:not(.mac) .lang_switcher a {
    font-size: 16px;
    font-size: 1rem;
    font-family: 'Arial';
  }
  html:not(.mac) .right_side_section__form .gfield input {
    font-family: 'Arial';
    font-family: 'Arial';
    font-size: 18px;
    font-size: 1.125rem;
  }
  html:not(.mac) .right_side_section__form .gfield input::-webkit-input-placeholder {
    color: #86763f;
    font-family: 'Arial';
  }
  html:not(.mac) .right_side_section__form .gfield input:-moz-placeholder {
    color: #86763f;
    font-family: 'Arial';
  }
  html:not(.mac) .right_side_section__form .gfield input::-moz-placeholder {
    color: #86763f;
    font-family: 'Arial';
  }
  html:not(.mac) .right_side_section__form .gfield input:-ms-input-placeholder {
    color: #86763f;
    font-family: 'Arial';
  }
  html:not(.mac) .right_side_section__form .gfield textarea {
    font-family: 'Arial';
    font-size: 18px;
    font-size: 1.125rem;
  }
  html:not(.mac) .right_side_section__form .gfield textarea::-webkit-input-placeholder {
    font-family: 'Arial';
  }
  html:not(.mac) .right_side_section__form .gfield textarea:-moz-placeholder {
    font-family: 'Arial';
  }
  html:not(.mac) .right_side_section__form .gfield textarea::-moz-placeholder {
    font-family: 'Arial';
  }
  html:not(.mac) .right_side_section__form .gfield textarea:-ms-input-placeholder {
    font-family: 'Arial';
  }
  html:not(.mac) .right_side_section__form .gform_button {
    font-family: 'Arial';
    font-size: 18px;
    font-size: 1.125rem;
  }
  html:not(.mac) .gform_confirmation_message {
    font-family: 'Arial';
    font-size: 18px;
    font-size: 1.125rem;
  }
  html:not(.mac) .validation_error {
    font-family: 'Arial';
    font-size: 18px;
    font-size: 1.125rem;
  }
  html:not(.mac) .validation_message {
    font-family: 'Arial';
    font-size: 18px;
    font-size: 1.125rem;
  }
  .logo img {
    height: 80px;
    height: 5rem;
  }
  .row_articles__content p {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .information_boxes__content p {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .right_side_section__form .gfield input::-webkit-input-placeholder {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .right_side_section__form .gfield input:-moz-placeholder {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .right_side_section__form .gfield input::-moz-placeholder {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .right_side_section__form .gfield input:-ms-input-placeholder {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
