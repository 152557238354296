@include mobile {
	.first_section {
		height: auto;
		&__content {
			top: auto;
			left: auto;
			width: 100%;
			transform: none;
			position: relative;
			@include rem('font-size',26px);
			@include rem('padding',60px 15px);
		}
	}
	.slideshow_main {
		&__item {
			height: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		&__text {
			top: auto;
			left: auto;
			width: 100%;
			transform: none;
			position: relative;
			@include rem('font-size',26px);
			@include rem('padding',60px 15px);
		}
		.owl-stage {
			display: flex;
		}
		.owl-item {
			flex: 1;
			height: auto;
			min-width: 100vw;
		}
	}
	.information_boxes {
		@include rem('margin-top',15px);
		@include rem('margin-bottom',15px);
		&__item {
			width: 100%;
			@include rem('margin-bottom',20px);
		}
		&__content {
			h4 {
				@include rem('font-size',20px);
			}
			p {
				@include rem('font-size',14px);
			}
		}
		&__item_container {
			a {
				@include rem('padding',20px);
			}
		}
	}
	.view_more_button {
		span {
			@include rem('font-size',20px);
			@include rem('padding-left',15px);
		}
	}
	.main_section {
		&--archive_container {
			@include rem('margin-top',30px);
		}
	}
	.page_title {
		@include rem('font-size',28px);
		@include rem('margin-bottom',20px);
	}
	.page_desc {
		width: 100%;
		@include rem('font-size',15px);
	}
	.left_side_section {
		width: 100%;
		.row_articles {
			@include rem('margin-top',40px);
		}
	}
	.right_side_section {
		width: 100%;
		&__form {
			@include rem('padding',20px);
			@include rem('margin-bottom',20px);
		}
	}
	.contact_description {
		@include rem('padding',20px);
		p {
			@include rem('font-size',16px);
		}
		ul {
			@include rem('margin-top',20px);
			li {
				@include rem('margin-bottom',15px);
			}
			span {
				@include rem('font-size',22px);
			}
		}
	}
	.anchor_icon {
		@include rem('width',30px);
		@include rem('height',30px);
	}
	.row_articles {
		&__item {
			display: block;
			margin-bottom: 20px;
			padding-bottom: 20px;
			&--no_thumb {
				.row_articles__content {
					margin-bottom: 0;
					@include rem('padding-bottom',0,true);
				}
			}
		}
		&__thumb {
			width: 100%;
			@include rem('height',220px);
		}
		&__title {
			@include rem('font-size',24px);
			@include rem('margin-bottom',20px,true);
		}
		&__content {
			width: 100%;
			padding: 0 !important;
			@include rem('margin-bottom',15px);
			.view_more_button {
				position: relative;
				@include rem('margin-top',10px);
			}
			p {
				@include rem('font-size',14px);
			}
		}
	}
	.column_articles {
		@include rem('margin-bottom',20px);
		&--sidebar {
			@include rem('padding',20px);
			.column_articles__item {
				@include rem('margin-bottom',20px);
			}
		}
		&__item {
			width: 100%;
			&:last-child {
				.member_info {
					margin-bottom: 0;
				}
			}
		}
		&__main_title {
			@include rem('font-size',26px);
			@include rem('margin-bottom',20px);
		}
	}
	.member_info {
		margin: 0;
		@include rem('padding-left',15px);
		&__list {
			@include rem('margin-top',15px);
			li {
				a {
					@include rem('font-size',16px);
				}
				&.telephone {
					&:before {
						@include rem('left',4px);
						@include rem('width',17px);
						@include rem('height',17px);
					}
				}
				&.mail {
					&:before {
						@include rem('left',3px);
						@include rem('width',19px);
					}
				}
				&.facebook {
					&:before {
						@include rem('left',7px);
						@include rem('width',11px);
						@include rem('height',20px);
					}
				}
			}
		}
		&__name {
			@include rem('font-size',24px);
			@include rem('margin-bottom',5px);
		}
		&__role {
			@include rem('font-size',16px);
		}
	}
	.search_box {
		@include rem('margin-bottom',30px);
		@include rem('padding-bottom',30px);
		&__header {
			display: none;
		}
		&__item {
			@include rem('padding',20px);
		}
		&__thumb {
			width: 100%;
			@include rem('height',200px);			
		}
		&__content {
			float: left;
			width: 100%;
			padding-left: 0;
		}
		&__location {
			@include rem('font-size',19px);
			@include rem('margin-bottom',10px);
		}
		&__location_name {
			@include rem('font-size',17px);
			@include rem('margin-bottom',10px);
		}
		&__specs {
			@include rem('margin-bottom',10px);
			span {
				@include rem('font-size',18px);
				@include rem('margin-right',15px);
			}
		}
		&__other {
			@include rem('font-size',16px);
			@include rem('margin-bottom',5px);
		}
		&__footer {
			@include rem('padding',15px 0);
		}
		&__logo {
			img {
				@include rem('height',40px);
			}
		}
	}
	.gm-style {
		.gm-style-iw-c {
			padding-left: 0;
		}
	}
	.locations_section {
		@include rem('margin-bottom',30px);
		&:last-child {
			margin-bottom: 0;
			@include rem('padding-bottom',30px);
			&:after {
				width: 100%;
			}
		}
		&__popup {
			&.onlyChild {
				min-width: auto;
			}
			.locations_section__inner_list {
				padding-bottom: 45px;
			}
			.locations_section__button {
				a {
					height: auto;
					@include rem('font-size',14px);
				}
			}
			.locations_section__item {
				width: 100% !important;
				min-height: auto;
				&:first-child {
					margin-top: 0;
				}
			}
			.locations_section__item_header {
				@include rem('font-size',16px);
			}
			.locations_section__inner_list {
				li {
					@include rem('margin-top',10px);
				}
				span {
					@include rem('font-size',14px);
				}
			}
		}
		&__header {
			display: block;
			min-height: auto;
			@include rem('padding',25px 10px);
			h4 {
				@include rem('font-size',32px);
			}
			p {
				width: 100%;
				@include rem('font-size',16px);
			}
		}
		&__item {
			width: 100%;
			min-height: auto;
			@include rem('padding-left',0);
			@include rem('padding-right',0);
			@include rem('margin-top',20px);
			@include rem('padding-bottom',50px);
		}
		&__button {
			padding: 0;
		}
	}
	.map_section {
		margin-top: 0;
		@include rem('margin-bottom',20px);
		&__map_locations {
			@include rem('height',400px);
			@include rem('margin-bottom',20px);
		}
		&__icon {
			@include rem('width',25px);
			@include rem('height',33px);
		}
		&__legend_button {
			width: 100%;
			text-align: center;
			@include rem('font-size',18px);
		}
		&__legend_list {
			@include rem('margin-top',10px);
			li {
				width: 50%;
				margin-right: 0;
				@include rem('margin-top',10px);
				@include rem('padding-right',15px);
				p {
					@include rem('font-size',14px);
				}
			}
		}
	}
	.cookie_popup {
		height: auto;
		p {
			float: left;
			width: 100%;
			line-height: 1.3em;
			@include rem('margin-top',5px);
		}
	}
	.cookie_buttons {
		float: left;
		width: 100%;
		text-align: center;
		@include rem('margin-top',5px);
		@include rem('margin-bottom',5px);
		a {
			float: none;
			display: inline-block;
		}
	}
}

@include mobile-tablet {
	.extended_container {
		&--header {
			float: left;
			height: auto;
			display: block;
			@include rem('padding',20px 15px);
		}
	}
	.mid_container {
		float: left;
		@include rem('padding',0 15px);
	}
	.header_navigation {
		li {
			a {
				line-height: 1.4em;
				@include rem('font-size',18px);
				@include rem('padding',7px 10px 0);
			}
		}
	}
	.activeNav {
		.hamburger {
			position: fixed;
			@include rem('right',15px);
		}
		.header_navigation {
			opacity: 1;
			visibility: visible;
		}
		.lang_switcher {
			margin-right: 55px;
		}
	}
	.lang_switcher {
		float: right;
		display: block;
		margin-top: 17px;
		margin-right: 25px;
	}
	.header_navigation {
		top: 0;
		left: 0;
		opacity: 0;
		z-index: 5;
		width: 100%;
		height: 100vh;
		position: fixed;
		visibility: hidden;
		background: rgba(255,255,255,0.9);
		.lang_switcher {
			text-align: center;
			@include rem('margin-top',20px);
		}
		li {
			width: 100%;
			text-align: center;
			@include rem('margin-bottom',20px);
		}
		&__container {
			top: 50%;
			left: 50%;
			width: 100%;
			position: absolute;
			transform: translate(-50%,-50%);
		}
		.lang_switcher {
			display: block;
		}
	}
	.mainWrap {
		overflow: hidden;
	}
	.main_section {
		.extended_container {
			padding: 0;
		}
	}
	.information_boxes {
		&__item_container {
			border-radius: 2px;
			box-shadow: 0 0 40px 0 #dbdbdb;
			-webkit-box-shadow: 0 0 40px 0 #dbdbdb;
		}
	}
	.footer_container {
		display: block;
		text-align: center;
		@include rem('margin-top',20px);
		@include rem('margin-bottom',20px);
	}
	.footer_text {
		width: 100%;
		margin-bottom: 0;
		text-align: center;
		@include rem('font-size',15px);
		@include rem('margin-top',10px);
	}
	.hamburger {
		display: block;
	}
	.footer_logo {
		@include rem('margin-bottom',8px);
		img {
			@include rem('height',55px);
		}
	}
	.logo {
		transform: none;
		position: static;
		img {
			@include rem('height',50px);
		}
	}
}

@include tablet {
	.information_boxes { 
		@include rem('margin-bottom',50px);
		&__item {
			width: 100%;
			@include rem('margin-bottom',20px);
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.first_section {
		height: 50vh;
		&__content {
			width: 100%;
			@include rem('font-size',46px);
			@include rem('padding',0 20px);
		}
	}
	.slideshow_main {
		height: 50vh;
		&__item {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		&__text {
			width: 100%;
			@include rem('font-size',46px);
			@include rem('padding',0 20px);
		}
		.owl-stage {
			display: flex;
		}
		.owl-item {
			flex: 1;
			height: 50vh;
			min-width: 100vw;
		}
	}
	.row_articles {
		&__title {
			@include rem('font-size',30px);
			@include rem('margin-bottom',25px);
		}
		&__content {
			width: 45%;
			p {
				@include rem('font-size',16px);
				@include rem('padding-top',11px);
			}
			.view_more_button {
				position: relative;
				@include rem('margin-top',20px);
			}
		}
		&__thumb {
			width: 55%;
		}
		&__item {
			@include rem('margin-bottom',30px);
			@include rem('padding-bottom',30px);
		}
	}
	.view_more_button {
		span {
			@include rem('font-size',20px);
			@include rem('padding-top',10px);
		}
	}
	.main_section {
		&--archive_container {
			@include rem('margin-top',30px);
		}
	}
	.page_desc {
		width: 100%;
	}
	.column_articles {
		&--sidebar {
			padding-bottom: 0;
			@include rem('padding',30px);
			.column_articles__item {
				width: 50%;
			}
		}
		&__items {
			.row {
				margin-left: -15px;
				margin-right: -15px;
			}
		}
		&__item {
			width: 50%;
			clear: left;
			margin-bottom: 0;
			@include rem('padding',0 15px);
			@include rem('margin-top',25px);
			&:nth-child(2n) {
				clear: none;
			}
			&:first-child,
			&:nth-child(2) {
				margin: 0;
			}
		}
		&__main_title {
			@include rem('font-size',30px);
			@include rem('margin-bottom',20px);
		}
	}
	.search_box {
		@include rem('margin-bottom',30px);
		@include rem('padding-bottom',30px);
		&__thumb {
			width: 50%;
			@include rem('height',220px);
		}
		&__content {
			width: 50%;
		}
		&__location {
			@include rem('font-size',16px);
			@include rem('margin-bottom',10px);
		}
		&__location_name {
			@include rem('font-size',18px);
			@include rem('margin-bottom',10px);
		}
		&__other {
			@include rem('font-size',16px);
		}
		&__specs {
			@include rem('margin-bottom',15px);
			span {
				@include rem('font-size',18px);
			}
		}
		&__footer {
			@include rem('padding',15px 0);
		}
		&__logo {
			img {
				@include rem('height',40px);
			}
		}
	}
	.left_side_section {
		width: 100%;
		.row_articles {
			@include rem('margin-top',30px);
		}
	}
	.right_side_section {
		width: 100%;
		&__form {
			@include rem('padding',30px);
			@include rem('margin-bottom',30px);
		}
	}
	.contact_description {
		@include rem('padding',20px);
		ul {
			@include rem('margin-top',20px);
			li {
				@include rem('margin-bottom',15px);
			}
			span {
				@include rem('font-size',22px);
			}
		}
	}
	.member_info {
		&__list {
			@include rem('margin-top',20px);
			li {
				a {
					@include rem('font-size',16px);
				}
				&:before {
					@include rem('left',3px,true);
					@include rem('width',18px,true);
					@include rem('height',18px,true);
				}
			}
		}
		&__name {
			@include rem('font-size',22px);
		}
		&__role {
			@include rem('font-size',16px);
		}
	}
	.map_section {
		@include rem('margin-top',20px);
		@include rem('margin-bottom',20px);
		&__legend_button {
			@include rem('font-size',20px);
			@include rem('padding',10px 50px);
			@include rem('margin-bottom',10px);
		}
		&__map_locations {
			@include rem('height',450px);
			@include rem('margin-bottom',30px);
		}
		&__legend_list {
			width: 100%;
			li {
				width: 25%;
				margin-right: 0;
				@include rem('margin-top',20px);
				@include rem('padding-right',10px);
			}
		}
		&__icon {
			@include rem('width',26px);
			@include rem('height',34px);
		}
	}
	.locations_section {
		@include rem('margin-bottom',40px);
		&:last-child {
			&:after {
				width: 100%;
			}
		}
		&__header {
			display: block;
			min-height: auto;
			@include rem('padding',40px);
			h4 {
				@include rem('font-size',32px);
			}
			p {
				width: 80%;
				@include rem('font-size',16px);
			}
		}
		&__item {
			float: left;
			width: 33.3333%;
			@include rem('padding',0 15px);
			@include rem('margin-top',30px);
		}
		&__list {
			@include rem('padding',0 12px);
		}
		&__button {
			a {
				@include rem('padding-top',8px);
			}
		}
	}
	.cookie_popup {
		height: auto;
		@include rem('padding-bottom',10px);
		p {
			width: 60%;
			margin-right: 0;
			line-height: 1.3em;
			@include rem('padding-right',10px);
		}
	}
	.cookie_buttons {
		@include rem('margin-top',10px);
	}
}

@include small-desktop {
	html {
		font-size: 70%;
		&:not(.mac) {
			.row_articles {
				&__content {
					p {
						font-family: 'Arial';
					}
				}
			}
			.information_boxes {
				&__content {
					p {
						font-family: 'Arial';
					}
				}
			}
			.locations_section {
				&__item {
					width: 25%;
				}
				&__item_header {
					font-family: 'Arial';
				}
				&__inner_list {
					li {
						span {
							font-family: 'Arial';
							@include rem('font-size',20px);
							@include rem('letter-spacing',1px);
						}
					}
				}
				&__button {
					a {
						font-family: 'Arial';
						@include rem('padding-top',6px);
						@include rem('font-size',17px);
						&:after {
							font-family: 'Arial';
							@include rem('padding-top',6px);
						}
					}
				}
			}
			.member_info {
				&__role {
					font-family: 'Arial';
				}
			}
			.contact_description {
				p {
					font-family: 'Arial';
				}
			}
			.header_navigation {
				li {
					a {
						font-family: 'Arial';
					}
				}
			}
			.page_desc {
				font-family: 'Arial';
			}
			.locations_section {
				&__header {
					p {
						font-family: 'Arial';
					}
				}
			}
			.lang_switcher {
				a {
					@include rem('font-size',16px);
					font-family: 'Arial';
				}
			}
			.right_side_section {
				&__form {
					.gfield {
						input {
							font-family: 'Arial';
							font-family: 'Arial';
							@include rem('font-size',18px);
							@include placeholder {
								color: $themeColor4;
								font-family: 'Arial';
							}
						}
						textarea {
							font-family: 'Arial';
							@include rem('font-size',18px);
							@include placeholder {
								font-family: 'Arial';
							}
						}
					}
					.gform_button {
						font-family: 'Arial';
						@include rem('font-size',18px);
					}
				}
			}
			.gform_confirmation_message {
				font-family: 'Arial';
				@include rem('font-size',18px);
			}
			.validation_error {
				font-family: 'Arial';
				@include rem('font-size',18px);
			}
			.validation_message {
				font-family: 'Arial';
				@include rem('font-size',18px);
			}
		}
	}
	.logo {
		img {
			@include rem('height',80px);
		}
	}
	.row_articles {
		&__content {
			p {
				@include rem('font-size',20px);
			}
		}
	}
	.information_boxes {
		&__content {
			p {
				@include rem('font-size',18px);
			}
		}
	}
	.right_side_section {
		&__form {
			.gfield {
				input {
					@include placeholder {
						@include rem('font-size',18px);
					}
				}
			}
		}
	}
}